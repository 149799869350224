import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { useParams, Link, Navigate } from 'react-router-dom';
import baseApiUrl from '../../../utility/baseApiUrl';
import {SET_PAGE_LOADER} from "../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import Layout from '../../../components/Layout/Layout';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import apiStoragePath from '../../../utility/apiStoragePath';
import useAuth from '../../../hooks/useAuth';

const axios = require("axios");
const EditConsultation = () => {

    let {id} = useParams();
    const auth = useAuth();

    const dispatch = useDispatch();
    const imagePath = apiStoragePath();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "mention"
      ];

      const   modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"]
        ]
      };

    const [formTitle] = useState('Edit Consultation');


    const [image, setImage] = useState(''); // changeHere - stateName correction
    const [showImage, setShowImage] = useState(''); // changeHere - stateName correction



    const [form, setForm] = useState({
        consultation_title: '',
        consultation_price: '',
        consultation_description: '',

    });

    const [consultation_description, setConsultationDescription] = useState("");


    // const resetForm = () => {

    //     setForm({
    //         consultation_title: '',
    //         consultation_price: '',
    //         consultation_description: '',
    //     })

    // }


    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })

        if (e.target.files[0]) {
            setImage(e.target.files[0])
            setShowImage({})
        }
    };

    const ConsDesFunc = value => {
        setConsultationDescription(value);
    }

    const loadConsultation = () => {

        axios
          .get(baseApiUrl()+"consultation/"+id)
          .then((res) => {

            setForm(res.data);
            setShowImage(res.data.consultation_image);
            setConsultationDescription(res.data.consultation_description);
            dispatch(SET_PAGE_LOADER(false));

          })
          .catch((err) => {
            dispatch(SET_PAGE_LOADER(false));
            console.log('server error', err);
            alert('Please reload again');
          })
      };


 

    const onSubmit = e => {

        e.preventDefault();

        dispatch(SET_PAGE_LOADER(true));

        let data = new FormData();
        data.append('consultation_title', form.consultation_title);
        data.append('consultation_price', form.consultation_price);
        data.append('consultation_description', consultation_description);

        if (image?.name) {
            data.append("consultation_image", image);
        }

        const apiUrl = baseApiUrl();

        axios.post(`${apiUrl}consultation-update/${id}`, data).then(res => {

            Toast.fire({
                icon: 'success',
                title: res.data.msg
            });

            dispatch(SET_PAGE_LOADER(false));

        }).catch((err) => {
            console.log(err);

            Toast.fire({
                icon: 'error',
                title: 'server error'
            });

            dispatch(SET_PAGE_LOADER(false));
        });
    }





    useEffect(() => {

        loadConsultation();

        dispatch(SET_PAGE_LOADER(true));

    }, []); 

    return (
        <Layout>
            <form onSubmit={onSubmit}>
                <div className='lg:flex gap-5'>

                    {/* left component */}
                    <div className='lg:w-8/12 bg-white rounded-md border shadow' >

                        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                            <h3>{formTitle}</h3>
                            <button className='button__bg px-6 py-2 rounded'><Link to={'/consultations'}>Consultation Lists</Link>
                            </button>
                        </div>

                        <div className='py-5 px-8'>

                            <div className="w-full mb-6">
                                <label className="form-label">Consultation title</label>
                                <input type="text"
                                    name='consultation_title'
                                    value={form.consultation_title}
                                    onChange={handleChange}
                                    className="form-field" required />
                            </div>


                            <div className="mb-6">
                                <label className="form-label">Consultation Description</label>

                                <ReactQuill value={consultation_description}
                                onChange={ConsDesFunc} 
                                modules={modules}
                                formats={formats}
                                />

                            </div>
                        </div>
                    </div>

                    {/* right component    */}
                    <div className='phone-mt lg:w-4/12'>
                        <div className='w-full bg-white rounded-md border shadow' >
                            <div className='px-4 py-4 mb-5 card__header border-2 text-md flex items-center justify-between '>
                                {/* <h3>{formTitle}</h3> */}
                                 <h1>Consultation</h1>
                            </div>
                            <div className='py-5 px-8'>
                           
                           
                               <div className="w-full mb-6">
                                    <label htmlFor="email" className="form-label">Consultation Price</label>
                                    <input type="number"
                                        name='consultation_price'
                                        value={form.consultation_price}
                                        onChange={handleChange}
                                        className="form-field" required />
                                </div>


                                <div className="w-full mb-6">
                               <div className=''>
                                   <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Consultation Image</label>
                                        <input type="file" className='border-2 rounded py-1 px-1 w-full'
                                            onChange={handleChange} 
                                        onClick={e => (e.target.value = null)}
                                        />
                                   </div>
                                   <div>

                                        {/* form image show */}
                                        {image.name ?
                                           <div className="mt-3">
                                            <img src={URL.createObjectURL(image)} alt="" srcset="img" className="w-full h-40 shadow sobject-cover " />
                                            </div>

                                          : <div className="mt-3">
                                            <img src={imagePath + showImage} alt="" srcset="img" className="w-full h-40 shadow sobject-cover " />
                                            </div>
                                        }

                                   </div>
                               </div>
                               
                           </div>


                                <div className='flex flex-wrap gap-3 '>
                                    <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                                    {/* <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p> */}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </Layout>
    );
};

export default EditConsultation;