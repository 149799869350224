// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useState, useEffect  } from 'react';
import Swal from 'sweetalert2';
import baseApiUrl from '../../../utility/baseApiUrl';
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import useAuth from '../../../hooks/useAuth';
import Layout from '../../../components/Layout/Layout';
const axios = require("axios");

const ShppingFee = () => {

    const dispatch = useDispatch();
    const auth = useAuth();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    const [formTitle] = useState('Shpping Page');

    const [form, setForm] = useState({
        fee : '',
    });


    const handleChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value })
     }

     const onSubmit = e =>{

        dispatch(SET_PAGE_LOADER(true));

        e.preventDefault();

        axios.put(baseApiUrl() + 'shpping-fee/1', form).then(res => {
                       
            Toast.fire({
                icon: 'success',
                title: res.data.msg
              });

              dispatch(SET_PAGE_LOADER(false));

        }).catch((err) => {
            
            console.log(err);
            Toast.fire({
                icon: 'error',
                title: 'Server error'
            });

            dispatch(SET_PAGE_LOADER(false));
                      
        });

     }

     useEffect(() => {

        dispatch(SET_PAGE_LOADER(true));
        axios
        .get(baseApiUrl() + "shpping-fee")
        .then((res) => {
            setForm(res.data)
            dispatch(SET_PAGE_LOADER(false));
        })
        .catch((err) =>{
             dispatch(SET_PAGE_LOADER(false));

            console.error(err)
        })
    }, []);  

    return (
        <Layout>
        <div>
        <div className='lg:px-24 gap-10'>
            <div className='w-full bg-white rounded-md border shadow' >
            <div className='px-4 py-3 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                   <h3>{formTitle}</h3>
            </div>
               <form onSubmit={onSubmit} className='py-5 px-8'>
                   <div className=''>

                       <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>
                            <div className="w-full mb-6">
                                <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Shpping Fee</label>
                                <input type="fee"
                                name='fee'
                                value={form.fee}
                                onChange={handleChange}
                                className="form-field" 
                                required
                                 />
                                </div>
                            </div>
                        </div>


                        <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
                   </div>
               </form>
            </div>

        </div>
      </div>
        </Layout>
    );
};

export default ShppingFee;