import { React, useEffect, useState } from 'react';
import baseApiUrl from '../../../utility/baseApiUrl';
import Swal from 'sweetalert2';
import { Link, Navigate } from 'react-router-dom';
import './style.css';
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import apiStoragePath from '../../../utility/apiStoragePath';
import Layout from '../../../components/Layout/Layout';
import useAuth from '../../../hooks/useAuth';
import Pagination from "react-js-pagination";

const axios = require("axios");

const Consultations = () => {

  const [consultation, setConsultation] = useState([]);
  const dispatch = useDispatch();
  const imagePath = apiStoragePath();
  const auth = useAuth();
  // load consultation from server
  const loadConsultation = (pageNumber = 1) => {
    dispatch(SET_PAGE_LOADER(true));

    axios
      .get(baseApiUrl() + "consultation?page="+pageNumber)
      .then((res) => {
        setConsultation(res.data);
        dispatch(SET_PAGE_LOADER(false));
      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));
        console.log('server error', err);
        alert('Please reload again');
      })
  };


  useEffect(() => {

    loadConsultation();
  }, []); 




  // delete consultation
  const deleteConsultation = id => {
    // console.log(form)
    
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        dispatch(SET_PAGE_LOADER(true));
        
        axios.delete(baseApiUrl() + 'consultation/' + id).then(res => {

          if (res.data.msg) {
            Swal.fire(
              'Deleted!',
              'Delete Success.',
              'success',
            )
            loadConsultation()
          }

          else {

            dispatch(SET_PAGE_LOADER(false));

            Swal.fire(
              'Error!',
              'Server error try again.',
              'error',
            )
          }

        })
      }
    })
  }

  return (
    <Layout>
      <div className='bg-white rounded-lg '>

        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
          <h3>Consultation List</h3>
          <button className='button__bg px-6 py-2 rounded'><Link to={'/create/consultation'}>Add Consultation</Link>
          </button>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Image
                </th>
                <th scope="col" className="px-6 py-3">
                  Image Title
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className='mt-5'>
              {consultation?.data?.map(item => {
                return (
                  <tr key={item} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      <img src={imagePath + item.consultation_image} className="w-16 border-2 " alt={item.image_title} />
                    </th>
                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.consultation_title}
                    </th>
                    {/* <td className="px-6 py-4">
                      Slug
                    </td> */}
                    <td className="px-6 py-4 flex gap-5 text-2xl">
                      <span onClick={() => deleteConsultation(item.id)} className=' cursor-pointer'> <AiOutlineDelete /> </span>
                      <Link to={`/edit/consultation/${item.id}`}> <AiOutlineEdit /></Link>
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>

            {consultation?.total > 5 && 
                <div className='pl-3 py-4'>
                    <Pagination
                        activePage={consultation?.current_page ? consultation?.current_page : 0}
                        itemsCountPerPage={consultation?.per_page ? consultation?.per_page : 0 }
                        totalItemsCount={consultation?.total ? consultation?.total : 0}
                        onChange={(pageNumber) => {
                          loadConsultation(pageNumber)
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        itemClassFirst="p-first-page-link"
                        itemClassLast="p-last-page-link"
                        activeClass="p-active"
                        activeLinkClass="p-active-link"
                        firstPageText="First Page"
                        lastPageText="Last Lage"
                    />
                </div>
              }
        </div>
      </div>
    </Layout>
  );
};

export default Consultations;