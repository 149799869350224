import React from 'react';
import { Route, Routes } from "react-router-dom";
import Dashboard from './Dashboard/Dashboard';
import CreateProductCategory from './Ecommerce/CategoryManagement/CreateProductCategory';
import EditProductCategory from './Ecommerce/CategoryManagement/EditProductCategory';
import Productcategory from './Ecommerce/CategoryManagement/Productcategory';
import CreateProduct from './Ecommerce/ProductManagement/CreateProduct';
import EditProduct from './Ecommerce/ProductManagement/EditProduct';
import Products from './Ecommerce/ProductManagement/Products';
import Consultations from './FreeTools/Consultations/Consultations';
import CreateConsultation from './FreeTools/Consultations/CreateConsultation';
import EditConsultation from './FreeTools/Consultations/EditConsultation';
import AddImage from './FreeTools/Image/AddImage';
import EditImage from './FreeTools/Image/EditImage';
import Images from './FreeTools/Image/Images';
import Login from './Login/Login';
import NoPageFound from './NoPageFound/NoPageFound';
import EditOrder from './OrderManagement/EditOrder/EditOrder';
import Orders from './OrderManagement/Orders/Orders';
import CreatePost from './PostManagement/Post/CreatePost';
import EditPost from './PostManagement/Post/EditPost';
import Posts from './PostManagement/Post/Posts';
import CreatePostCategory from './PostManagement/PostCategory/CreatePostCategory';
import EditPostCategory from './PostManagement/PostCategory/EditPostCategory';
import PostCategory from './PostManagement/PostCategory/PostCategory';
import CreateUser from './UserManagement/CreateUser/CreateUser';
import Users from './UserManagement/Users/Users';
import EditUser from './UserManagement/EditUser/EditUser';
import SiteSetting from './Setting/SiteSetting/SiteSetting';
import Newsletter from './Newsletter/Newsletter';
import ProductReview from './Ecommerce/ProductReview/ProductReview';
import EditProductReview from './Ecommerce/ProductReview/EditProductReview/EditProductReview';
import ShppingFee from './Ecommerce/ShppingFee/ShppingFee';
import ProtectedRoutes from '../hooks/ProtectedRoutes';
import CreateCoupon from './Ecommerce/Coupon/CreateCoupon/CreateCoupon';
import Coupon from './Ecommerce/Coupon/Coupon/Coupon';
import EditCoupon from './Ecommerce/Coupon/EditCoupon/EditCoupon';
import CreateProductSubCategory from './Ecommerce/SubCategoryManagement/CreateProductSubCategory';
import ProductSubCategory from './Ecommerce/SubCategoryManagement/ProductSubCategory';
import EditProductSubCategory from './Ecommerce/SubCategoryManagement/EditProductSubCategory';
import Forecast from './Forecast/Forecast';
import CreateForecast from './Forecast/CreateForecast';
import EditForecast from './Forecast/EditForecast';
import Attribute from './Ecommerce/Attribute/Attribute';
import AttributeItem from './Ecommerce/Attribute/AttributeItem';
function Master() {

  return (
    <div>

            <div className=''>
              
                  <Routes>
                  <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="create/product/category" element={<CreateProductCategory />} />
                    <Route path="product/category" element={<Productcategory />} />

                    <Route path="edit/product/category/:id" element={<EditProductCategory />} />
                    <Route path="create/product" element={<CreateProduct />} />
                    <Route path="edit/product/:id" element={<EditProduct />} />
                    <Route path="products" element={<Products />} />

                    <Route path="product/review" element={<ProductReview />} />
                    <Route path="product/review/edit/:id" element={<EditProductReview />} />
                    {/* protection doone  */}

                    <Route element={<ProtectedRoutes/>}>
                        <Route path="posts" element={<Posts />} />
                        <Route path="create/post/category" element={<CreatePostCategory />} />
                        <Route path="edit/post/category/:id" element={<EditPostCategory />} />
                        <Route path="post/category" element={<PostCategory />} />
                        <Route path="create/post" element={<CreatePost />} />
                        <Route path="edit/post/:id" element={<EditPost />} />
                        <Route path="free-tools/add/product-guide" element={<AddImage />} />
                        <Route path="free-tools/edit/image/:id" element={<EditImage />} />
                        <Route path="free-tools/product-guide" element={<Images />} />
                        <Route path="consultations" element={<Consultations />} />
                        <Route path="create/consultation" element={<CreateConsultation />} />
                        <Route path="edit/consultation/:id" element={<EditConsultation />} />
                        <Route path="create/user" element={<CreateUser />} />
                        <Route path="edit/user/:id" element={<EditUser />} />
                        <Route path="users" element={<Users />} />
                        <Route path="orders" element={<Orders />} />
                        <Route path="order/edit/:id" element={<EditOrder />} />
                        <Route path="/site/setting" element={<SiteSetting />} />
                        <Route path="newsletter" element={<Newsletter />} />
                        <Route path="product/shpping-fee" element={<ShppingFee />} />
                        <Route path="coupon" element={<Coupon />} />
                        <Route path="create/coupon" element={<CreateCoupon />} />
                        <Route path="edit/coupon/:id" element={<EditCoupon />} />

                        <Route path="product/subcategory" element={<ProductSubCategory />} />
                        <Route path="create/product/subcategory" element={<CreateProductSubCategory />} />
                        <Route path="edit/product/subcategory/:id" element={<EditProductSubCategory />} />


                        <Route path="forecast" element={<Forecast />} />
                        <Route path="create/forecast" element={<CreateForecast />} />
                        <Route path="edit/forecast/:id" element={<EditForecast />} />

                        <Route path="attributes" element={<Attribute />} />
                        <Route path="attribute/:id" element={<AttributeItem />} />

                    </Route>
                   
                      {/* // 404 page  */}
                    <Route  path="*" element={<NoPageFound />}  />
                  </Routes>
            </div>

        </div>
  );
}

export default Master;
