import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { SET_PAGE_LOADER } from "../../redux/actions/loaderAction";

const Login = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    
    // const [forgetPass, setForgetPass] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        email: '',
        password: '',
        errors: [],
    });

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const handleChange = (e) => {

        setForm({ ...form, [e.target.name]: e.target.value })
        // console.log(form)
    }

    const onSubmit = (e) => {
       
        console.log(loading);

        e.preventDefault();
      

        setLoading(true);
        dispatch(SET_PAGE_LOADER(true));
        // axios.post(`http://2-pakbettv-serverside.test/api/login`, form)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/login`, form)
            .then(res => {
                setLoading(false);

                dispatch(SET_PAGE_LOADER(false));


                console.log("test_102_data", res.data)
                console.log("test_102_status", res.status)
                if (res.status >= 200 && res.status < 3000) {
                    // console.log("cehck_case_1")
                    // localStorage.setItem("sohag", "hi2")

                    Toast.fire({
                        icon: 'success',
                        title: 'Login Success'
                    });
                    // console.log("cehck_case_2")
                    // localStorage token & user update 
                    // set token to localStorage
                    const token = res.data.token;
                    // localStorage.setItem("token_value", token);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    // console.log("cehck_case_3")
                    // set user to localStorage
                    const userInfo = res.data.user;
                    // localStorage.setItem("user", JSON.stringify(userInfo));
                    // localStorage.setItem("isLoggedIn", true);
                    // console.log("cehck_case_4")

                    // Get user permission from json objet 
                    if (JSON.stringify(userInfo) !== "{}") {
                        dispatch({
                            type: "login_status_update",
                            payload: true
                        })
                        // console.log("cehck_case_5")
                        dispatch({
                            type: "tokenValue_update",
                            payload: token
                        })
                        // console.log("cehck_case_6", userInfo)
                        dispatch({
                            type: "loggedinUserInfo_update",
                            payload: userInfo
                        })
                        // console.log("cehck_case_7")
                    } else {
                        dispatch({
                            type: "authUserPermission_update",
                            payload: ""
                        })
                    }
                    // console.log("cehck_case_8")
                    navigate("/dashboard");


                }
                else {
                    // localStorage.setItem("isLoggedIn", true);
                    // login failed
                    dispatch(SET_PAGE_LOADER(false));

                    Toast.fire({
                        icon: 'error',
                        title: 'Email or password is incorrect'
                    });
                }
            })
            .catch(error => {
                dispatch(SET_PAGE_LOADER(false));

                setLoading(false);
                console.log("test_103_error", error);
                Toast.fire({
                    icon: 'error',
                    title: 'Email or password is incorrect'
                });
            })
    }

    // useEffect( () =>{
    //     dispatch({type: 'PAGE_LOADER'});
    // },[])


    return (
        <>
            <section style={{ backgroundColor: '#E3F2FD', minHeight: '100vh' }} className='py-16'>
                <div className='mt-16 bg-white py-12 px-12 sm:w-[100%] md:w-[80%] lg:w-[50%] xl:w-[40%] 2xl:w-[30%] mx-auto my-auto rounded-lg shadow-sm login-center '>
                    <h2 style={{ fontFamily: "'Roboto', sans-serif" }} className='text-3xl leading-5 font-bold text-center text-purple-700'>Hi, Welcome Back</h2>
                    <p className='text-center mt-8 text-lg text-gray-400'>Enter your credentials to continue</p>
                    <p className='text-center text-xl mt-4 text-zinc-600'>Sign in with Email address</p>
                    <form onSubmit={onSubmit}>
                        <div className="mt-6">
                            <input className='block w-full px-4 py-4 border border-gray-300 mb-4 rounded-lg' type="email" placeholder='Email Address/username' value={form.email} name='email' onChange={handleChange} />
                            <input className='block w-full px-4 py-4 border border-gray-300 mb-4 rounded-lg' type="password" placeholder='Password' value={form.password} name='password' onChange={handleChange} />
                            <button type="submit" className='px-16 py-4 bg-purple-700 text-white font-medium rounded-lg w-full'>Login</button>
                        </div>
                    </form>
                    
                </div>
            </section>

        </>
    );
};

export default Login;