// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { Link, useParams, Navigate } from 'react-router-dom';
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction";
import { useDispatch } from 'react-redux';
import Layout from "../../../components/Layout/Layout";
import apiStoragePath from '../../../utility/apiStoragePath';
import baseApiUrl from '../../../utility/baseApiUrl';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import useAuth from "../../../hooks/useAuth";

const axios = require("axios");
const EditPost = () => {

  const dispatch = useDispatch();
  const auth = useAuth();

  let { id } = useParams();

  const apiUrl = baseApiUrl();

  const imagePath = apiStoragePath();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  
  const [post_description, setPostDescription] = useState("");

  const postDesFunc = value => {
           setPostDescription(value);
           console.log('test_909', post_description)
   }


   const formats = [
       "header",
       "bold",
       "italic",
       "underline",
       "strike",
       "blockquote",
       "list",
       "bullet",
       "indent",
       "link",
       "image",
       "mention"
     ];

     const   modules = {
       toolbar: [
         [{ header: [1, 2, false] }],
         ["bold", "italic", "underline", "strike", "blockquote"],
         [{ list: "ordered" }, { list: "bullet" }],
         ["link", "image"]
       ]
     };

  const [formTitle] = useState('Edit Post');


  const [image, setImage] = useState({}); // changeHere - stateName correction


  const [category, setCategory] = useState([]);


  const [form, setForm] = useState({
    post_title: '',
    post_category_id: '',
    post_image: '',

  });

  const resetForm = () => {

    setForm({
      post_title: '',
      post_category_id: '',
    })

    setImage({});
    setPostDescription("");
  }


  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })

    if (e.target.files[0]) {
      setImage(e.target.files[0])
    }
  }

  // load category from server
  const loadCategory = () => {
   
    dispatch(SET_PAGE_LOADER(true));

    axios
      .get(baseApiUrl() + "backend-category-data/post")
      .then((res) => {
        setCategory(res.data);
        dispatch(SET_PAGE_LOADER(false));

      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));
        console.log('server error', err);
        alert('Please reload again');
      })
  };



  // load category from server
  const loadPost = () => {
    axios
      .get(`${apiUrl}posts/${id}`)
      .then((res) => {
        setForm(res.data);
        setPostDescription(res.data.post_description)
        dispatch(SET_PAGE_LOADER(false));
      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));
        console.log('server error', err);
        alert('Please reload again');
      })
  };


  const onSubmit = e => {

    e.preventDefault();

    dispatch(SET_PAGE_LOADER(true));

    let data = new FormData();
    data.append('post_title', form.post_title);
    data.append('post_category_id', form.post_category_id);
    data.append('post_description',  post_description);

    if (image?.name) {
      data.append("post_image", image);
    }

    axios.post(`${apiUrl}post-update/${id}`, data).then(res => {

      console.log(res)

      Toast.fire({
        icon: 'success',
        title: res.data.msg
      });

      loadPost();
      dispatch(SET_PAGE_LOADER(false));

    }).catch((err) => {
      console.log(err);

      Toast.fire({
        icon: 'error',
        title: 'server error'
      });

      dispatch(SET_PAGE_LOADER(false));
    });
  }


  useEffect(() => {
    dispatch(SET_PAGE_LOADER(true));
    loadCategory();
    loadPost();
  }, []); 

  return (
    <Layout>
      <form onSubmit={onSubmit}>
        <div className='lg:flex gap-5 height '>

          {/* left component */}
          <div className='lg:w-8/12 bg-white rounded-md border shadow' >

            <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
              <h3>{formTitle}</h3>
               <Link to={'/posts'}>
                     <button className='button__bg px-6 py-2 block rounded'>Post Lists</button>
                   </Link>
            </div>

            <div className='py-5 px-8'>

              <div className="w-full mb-6">
                <label className="form-label">Post title</label>
                <input type="text"
                  name='post_title'
                  value={form.post_title}
                  onChange={handleChange}
                  className="form-field" required />
              </div>

              <div className="mb-6">
                <label className="form-label">Post Description</label>

                <ReactQuill value={post_description}
                                onChange={postDesFunc} 
                                modules={modules}
                                formats={formats}
                                />

                {/* <textarea className="form-field" rows="10" cols="50" maxlength="200"  name='post_description'
                                        value={form.post_description}
                                        onChange={handleChange} 
                                    /> */}
              </div>
            </div>
          </div>

          {/* right component    */}
          <div className='phone-mt lg:w-4/12'>
            <div className='w-full bg-white rounded-md border shadow' >
              <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                {/* <h3>{formTitle}</h3> */}
                   <Link to={'/post/category'}>
                           <button className='button__bg px-6 py-2 block rounded'>Category Lists</button>
                  </Link>
              </div>
              <div className='py-5 px-8'>
                <div className="mb-6">
                  <label className="form-label">Select Category</label>
                  <select className="select-field" name="post_category_id" required onChange={handleChange} value={form.post_category_id}>
                    <option value="" selected>Select Category</option>
                    {category.map(item => {
                      return (
                        <option key={item} value={item.id}>{item.category_name}</option>
                      )
                    })}
                  </select>
                </div>


                <div className="w-full mb-6">
                  <label className="form-label">Featured Image</label>
                  <input type="file" className='border-2 rounded py-1 px-1 w-full'
                    onChange={handleChange}
                    onClick={e => (e.target.value = null)}
                  />
                </div>

                <div className="my-5">
                  
                   {image.name ? 

                      <img src={URL.createObjectURL(image)} alt="img" className="border-2 "/>

                    : <img src={imagePath + form.post_image} alt="img" className="border-2"/>
                    }

                </div>


                <div className='flex flex-wrap gap-3 '>
                  <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                  <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </Layout>
  );
};

export default EditPost;