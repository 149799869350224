// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useState, useEffect  } from 'react';
import Swal from 'sweetalert2';
import { Link, Navigate, useParams } from 'react-router-dom';
import baseApiUrl from '../../../../utility/baseApiUrl';
import {SET_PAGE_LOADER} from "../../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import Layout from '../../../../components/Layout/Layout';
const axios = require("axios");
const EditCoupon = () => {

    const dispatch = useDispatch();
    let { id } = useParams();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    const [formTitle] = useState('Edit Coupon');
    const [users, setUser] = useState([]);


    const [form, setForm] = useState({
        coupon_code : '',
        amount : '',
        type : '',
        expiry_date : '',
        minimum_cost : '',
        usage_limit : '',
        status : '',
        user_id : '',
        category_id: '',
    });

    const handleChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value })
    };


     const onSubmit = e =>{

        dispatch(SET_PAGE_LOADER(true));

        e.preventDefault();

        axios.put(baseApiUrl() + 'coupon/'+id, form).then(res => {
                       
            Toast.fire({
                icon: 'success',
                title: res.data.msg
              });

              dispatch(SET_PAGE_LOADER(false));

        }).catch((err) => {
            
            Toast.fire({
                icon: 'error',
                title: 'Server error'
            });
            
            dispatch(SET_PAGE_LOADER());
                      
        });

     }


   // load users from server
  const loadUser = () => {
    axios
      .get(baseApiUrl() + "allusers")
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => console.error(err))
      .then(() => {
        console.log("final done");
      });

   };

   // load users from server
  const loadCoupon = () => {

    dispatch(SET_PAGE_LOADER(true));

    axios
      .get(baseApiUrl() + "coupon/"+id)
      .then((res) => {
        setForm(res.data);
        dispatch(SET_PAGE_LOADER(false));

      })
      .catch((err) => {
            
        dispatch(SET_PAGE_LOADER(false));
        Toast.fire({
            icon: 'error',
            title: 'Server error'
        });
        
                  
    });

   };

    useEffect(() => {
        loadUser();
        loadCoupon();
    }, []); 


    return (
        <div>
            <Layout>
                <div>
                <div className='lg:px-24 gap-10'>
                    <div className='w-full bg-white rounded-md border shadow' >
                    <div className='px-4 py-2 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                        <h3>{formTitle}</h3>

                        <Link to={'/coupon'}>
                            <button className='button__bg px-6 py-2 block rounded'>Coupon Lists</button>
                        </Link>
                    </div>
                    <form onSubmit={onSubmit} className='py-5 px-8'>
                        <div className=''>

                            <div className='grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                                    <div className="w-full mb-6">
                                        <div>
                                            <label  className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Coupon Type</label>
                                            <select className="select-field" name="type" required onChange={handleChange} value={form.type}>
                                            <option value="" selected>Select Type</option>
                                            <option value="1" selected>Fixed</option>
                                            <option value="2" selected>Percentage</option>
                                        </select>
                                      </div>
                                    </div>

                                    <div className="w-full mb-6">
                                        <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Coupon Code</label>
                                        <input type="text"
                                        name='coupon_code'
                                        value={form.coupon_code}
                                        onChange={handleChange}
                                        className="form-field" 
                                        required
                                        />
                                        </div>
                                    </div>

                                    <div className="w-full mb-6">
                                        <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Coupon Amount </label>
                                        <input type="number"
                                        name='amount'
                                        value={form.amount}
                                        onChange={handleChange}
                                        className="form-field" required />
                                        </div>

                                    </div>

                                </div>


                                <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
                        </div>
                    </form>
                    </div>

                </div>
            </div>
                </Layout>
        </div>
    );
};

export default EditCoupon;