// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { Link, useParams, Navigate } from 'react-router-dom';
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import apiStoragePath from '../../../utility/apiStoragePath';
import baseApiUrl from '../../../utility/baseApiUrl';
import Layout from '../../../components/Layout/Layout';
import useAuth from "../../../hooks/useAuth";

import './style.css'
const axios = require("axios");
const EditPostCategory = () => {

  let { id } = useParams();
  const imagePath = apiStoragePath();
  const auth = useAuth();


  const dispatch = useDispatch();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const [formTitle] = useState('Edit Category');

  const [image, setImage] = useState({}); // changeHere - stateName correction

  const [showimage, setShowImage] = useState({}); // changeHere - stateName correction

  const [form, setForm] = useState({
    category_name: '',
    category_description: '',
    category_image: ''
  });


  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })

    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setShowImage({});
    }
  }

  // load category from server
  const loadCategory = () => {
    dispatch(SET_PAGE_LOADER(true));

    axios
      .get(baseApiUrl() + "post/category/" + id)
      .then((res) => {
        setForm(res.data);
        setShowImage(res.data.category_image);
        dispatch(SET_PAGE_LOADER(false));
      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));
        console.log('server error', err);
        alert('Please reload again');
      })
  };


  useEffect(() => {
    loadCategory();
  }, []); 


  //   update category
  const onSubmit = e => {

    e.preventDefault();

    dispatch(SET_PAGE_LOADER(true));

    let data = new FormData();
    data.append('category_name', form.category_name);
    data.append('category_description', form.category_description);

    if (image?.name) {
      data.append("category_image", image);
    }

    const apiUrl = baseApiUrl();

    axios.post(`${apiUrl}post/category-update/${id}`, data).then(res => {

      console.log(res)

      Toast.fire({
        icon: 'success',
        title: res.data.msg
      });

      // loadCategory();

      dispatch(SET_PAGE_LOADER(false));

    }).catch((err) => {

      console.log(err);
      dispatch(SET_PAGE_LOADER(false));

      Toast.fire({
        icon: 'error',
        title: 'server error'
      });
    });

  }

  return (
    <Layout>
      <div>
        <div className='lg:px-24 gap-10'>
          <div className='w-full bg-white rounded-md border shadow' >
            <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
              <h3>{formTitle}</h3>
                 <Link to={'/post/category'}>
                     <button className='button__bg px-6 py-2 block rounded'>Category Lists</button>
                   </Link>
            </div>
            <form onSubmit={onSubmit} className='py-5 px-8'>
              <div className=''>

                <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                    <div className="w-full mb-6">
                        <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Category Name</label>
                        <input type="text"
                          name='category_name'
                          value={form.category_name}
                          onChange={handleChange}
                          className="form-field" required />
                        </div>

                    <div className="w-full mt-8">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Image</label>
                      <input type="file" className='border-2 rounded py-1 px-1 w-full'
                        onChange={handleChange}
                        onClick={e => (e.target.value = null)}
                        />
                    </div>
                   </div>

                  <div className="my-5 flex justify-end ">
                   
                    {image.name ? 
                      <img src={URL.createObjectURL(image)} alt="img" className="border-2 post__image "/>
                    : <div>
                      {form.category_image && 
                      <img src={imagePath + showimage} alt="img" className="border-2 post__image "/>
                       }
                      </div>}
                  </div>

                </div>

               

                <div className="mb-6">
                  <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Description</label>
                  <textarea id="txtid" className="form-field" rows="10" cols="50" maxlength="200" name='category_description'
                    value={form.category_description}
                    onChange={handleChange}
                  />
                </div>

                <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </Layout>
  );
};

export default EditPostCategory;