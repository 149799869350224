// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { Link, Navigate } from 'react-router-dom';
import baseApiUrl from '../../../utility/baseApiUrl';
import {SET_PAGE_LOADER} from "../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import Layout from '../../../components/Layout/Layout';
import useAuth from '../../../hooks/useAuth';

const axios = require("axios");

const AddImage = () => {

    const dispatch = useDispatch();
    const auth = useAuth();

    const ref = useRef();
    const pdf_file_ref = useRef();


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    const [formTitle] = useState('Add Product Guide');

    const [image, setImage] = useState({}); // changeHere - stateName correction
    const [pdfFile, setPDFFile] = useState({}); // changeHere - stateName correction

    const [form, setForm] = useState({
        image_title : '',
    });

    const resetForm = () =>{
        setForm({
            image_title : '',
        })

        resetImg();
    }


    const handleChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value })
        
        if(e.target.files[0]){
            setImage(e.target.files[0])
        }
    }
    
    
    const uploadPDF = e =>{
        setPDFFile(e.target.files[0])
        // console.log('pdf_1o1', e.target.files)
     }

     const resetImg = () => {
        ref.current.value = "";
        pdf_file_ref.current.value = "";
        setPDFFile({});
        setImage({});
      };
     

     const onSubmit = e =>{

        dispatch(SET_PAGE_LOADER(true));

        e.preventDefault();

        let data = new FormData();
        data.append('image_title', form.image_title);
        data.append("pdf_file", pdfFile);

        if(image?.name){
            data.append("image", image);
          }   

        axios.post(baseApiUrl() + 'image', data).then(res => {
           
            console.log(res)
            
            Toast.fire({
                icon: 'success',
                title: res.data.msg
              });

              dispatch(SET_PAGE_LOADER(false));

              resetForm();

        }).catch((err) => {
            dispatch(SET_PAGE_LOADER(false));
            console.log('server error', err);
            alert('Please reload again');
          })
     }

    return (
        <Layout>
        <div>
        <div className='lg:px-24 gap-10'>
            <div className='w-full bg-white rounded-md border shadow' >
            <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                   <h3>{formTitle}</h3>
                <button className='button__bg px-6 py-2 rounded'><Link to={'/free-tools/product-guide'}>Product Guide Lists</Link>
              </button>
            </div>
               <form onSubmit={onSubmit} className='py-5 px-8'>
                   <div className=''>

                       <div className=''>
                           <div className="w-full mb-6">
                               <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Product Guide Title</label>
                                <input type="text" 
                                       name='image_title'
                                       value={form.image_title}
                                       onChange={handleChange} 
                                className="form-field" required />
                           </div>

                           <div className="w-full mb-6">
                               <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>
                                   <div>
                                       <div>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Product Guide Image</label>
                                            <input  required type="file" ref={ref} className='border-2 rounded py-1 px-1 w-full'
                                                onChange={handleChange} 
                                                onClick={e => (e.target.value = null)}
                                            />
                                        </div>

                                       <div className='mt-6'>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Product Guide PDF File</label>

                                            <input  required type="file" ref={pdf_file_ref} className='border-2 rounded py-1 px-1 w-full'
                                                onChange={uploadPDF}
                                                onClick={e => (e.target.value = null)}
                                            />
                                        </div>

                                        <div className='mt-4'>
                                                 {pdfFile.name && <div>
                                                 PDF File :  {pdfFile.name}
                                                 </div>}
                                            </div>
                                   </div>


                                   <div className='flex justify-end '>
                                        {image.name && 
                                        <div className="mt-3">
                                            <img src={URL.createObjectURL(image)} alt="" className="w-full h-40 shadow-sm object-cover " />
                                            </div>
                                        }
                                   </div>
                               </div>
                               
                           </div>
                       </div>

                       <div className='flex flex-wrap gap-3 '>
                            <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                            <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p>
                       </div>
                   </div>
               </form>
            </div>

        </div>
      </div>
      </Layout>
    );
};

export default AddImage;