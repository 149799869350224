// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useState, useEffect  } from 'react';
import Swal from 'sweetalert2';
import { Link, Navigate, useParams } from 'react-router-dom';
import baseApiUrl from '../../../../utility/baseApiUrl';
import {SET_PAGE_LOADER} from "../../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import Layout from '../../../../components/Layout/Layout';
import useAuth from '../../../../hooks/useAuth';
const axios = require("axios");
const EditProductReview = () => {

    const dispatch = useDispatch();
    let { id } = useParams();

    const auth = useAuth();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    const [formTitle] = useState('Edit Review');

    const [review, setReview] = useState({
            is_approve: "",
            rating: "",
            review: "",
            user: "",
    });
    const handleChange = (e) => {
      setReview({...review, [e.target.name]: e.target.value })
    };



     const onSubmit = e =>{

        dispatch(SET_PAGE_LOADER(true));

        e.preventDefault();

        var rating = parseInt(review.rating); //10

         let data = {
            is_approve: review.is_approve,
            rating:  rating,
            review: review.review,
         }

        axios.put(baseApiUrl() + 'product-review/'+id, data).then(res => {
                       
            Toast.fire({
                icon: 'success',
                title: res.data.msg
              });

            dispatch(SET_PAGE_LOADER(false));

        }).catch((err) => {
            
          dispatch(SET_PAGE_LOADER(false));
           
            Toast.fire({
                icon: 'error',
                title: 'Server error'
            });
            
                      
        });

     }


     // load cloadUserategory from server
  const loadProductReview = () => {
    axios
      .get(baseApiUrl() + "product-review/"+id)
      .then((res) => {
                
        setReview(res.data);

        dispatch(SET_PAGE_LOADER(false));

        console.log('review_res', res.data)

      })
      .catch((err) => {
        console.error(err);
        dispatch(SET_PAGE_LOADER());

      })

  };

  useEffect(() => {

    dispatch(SET_PAGE_LOADER(true));
    loadProductReview();
  }, []); 

    return (
      auth ? <>
        <Layout>
        <div>
        <div className='lg:px-24 gap-10'>
            <div className='w-full bg-white rounded-md border shadow' >
            <div className='px-4 py-2 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                   <h3>{formTitle}</h3>

                   <Link to={'/product/review'}>
                     <button className='button__bg px-6 py-2 block rounded'>Product Review Lists</button>
                   </Link>
            </div>
               <form onSubmit={onSubmit} className='py-5 px-8'>


               <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                        <div className="w-full mb-6">
                            <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Review Info</label>

                            {review.user ? 
                          
                              <> 
                                 <p className='my-3'> Name : {review.user.name}</p> 
                                 <p > Email : {review.user.email}</p> 
                              </> 

                            :<p>User not found</p>
                            }


                            {review.product ? 
                              
                              <> 
                                <p className='mt-5' > Product : {review.product.product_title}</p> 
                              </> 

                            :<p className='mt-5' >No Product found </p>
                            }
                             
                            </div>

                        </div>

                          <div className="w-full my-6">


                          <div className='mb-6'>
                                <label  className="form-label">Change status</label>
                                <select 
                                    name='is_approve'
                                    value={review.is_approve}
                                    onChange={handleChange} 
                                    className="select-field"
                                 >
                                    <option value="0" >Pending
                                    </option>
                                    <option value="1" >Approve
                                    </option>
                                    <option value="2" >Spam
                                    </option>
                                </select>
                            </div>

                            <div>
                                <label  className="form-label">Change Rating</label>
                                <select 
                                    name='rating'
                                    value={review.rating}
                                    onChange={handleChange} 
                                    className="select-field"
                                 >
                                    <option value="1" >1
                                    </option>
                                    <option value="2" >2
                                    </option>
                                    <option value="3" >3
                                    </option>
                                    <option value="4">4
                                    </option>
                                    <option value="5">5
                                    </option>
                                </select>
       
                            </div>
                           
                        </div>
                        </div>
                 
                   <div className=''>

                       <div className="mb-6">
                            <textarea placeholder="Write review" className="form-field" rows="7" cols="10" 
                                name='review'
                                value={review.review}
                                onChange={handleChange} 
                                required
                            />
                        </div>


                       <div className='flex flex-wrap gap-3 '>
                            <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
                       </div>
                   </div>
               </form>
            </div>

        </div>
      </div>
        </Layout>

       </>:  <Navigate to="/login" />
    );
};

export default EditProductReview;