// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Navigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../components/Layout/Layout';
import useAuth from '../../../hooks/useAuth';
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction";
import apiStoragePath from '../../../utility/apiStoragePath';
import baseApiUrl from '../../../utility/baseApiUrl';

const axios = require("axios");
const EditProductSubCategory = () => {
  const auth = useAuth();

  let { id } = useParams();
  const imagePath = apiStoragePath();


  const dispatch = useDispatch();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const formTitle = 'Edit Category';

  const [image, setImage] = useState({}); // changeHere - stateName correction
  const [showimage, setShowImage] = useState({}); // changeHere - stateName correction


  const [category, setCategory] = useState([]);

  const [form, setForm] = useState({
    parent_category_id : '',
    category_name : '',
    show_searchbar : '',
    category_description : '',
});


  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })

    if (e.target.files[0]) {
      setImage(e.target.files[0])
    }
  }

  // load category from server
  const loadCategory = () => {
    dispatch(SET_PAGE_LOADER(true));

    axios
      .get(baseApiUrl() + "product-subcategory/" + id)
      .then((res) => {
        setForm(res.data);
        setShowImage(res.data.category_image);
        dispatch(SET_PAGE_LOADER(false));
      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));
        console.log('server error', err);
        alert('Please reload again');
      })
  };


  useEffect(() => {
    loadCategory();
  }, []);  


  //   update category
  const onSubmit = e => {

    e.preventDefault();

    dispatch(SET_PAGE_LOADER(true));

    let data = new FormData();
    data.append('id', id);
    data.append('category_name', form.category_name);
    data.append('show_searchbar', form.show_searchbar);
    data.append('category_description', form.category_description);
    data.append('parent_category_id', form.parent_category_id);


    if (image?.name) {
      data.append("category_image", image);
    }

    const apiUrl = baseApiUrl();

    axios.post(`${apiUrl}product/subcategory-update/${id}`, data).then(res => {

      Toast.fire({
        icon: 'success',
        title: res.data.msg
      });

      dispatch(SET_PAGE_LOADER(false));

    }).catch((err) => {
      console.log(err);
      dispatch(SET_PAGE_LOADER(false));

      Toast.fire({
        icon: 'error',
        title: 'server error'
      });
    });

    console.log(data)
  };

   
     // load category from server
     const loadParentCategory = () => {
      axios
        .get(baseApiUrl() + "backend-category-data/product")
        .then((res) => {
          setCategory(res.data);

          dispatch(SET_PAGE_LOADER(false));
          
        })
        .catch((err) => {
          dispatch(SET_PAGE_LOADER(false));
          console.log('server error', err);
          alert('Please reload again');
        })

    };

    
  useEffect(() => {
      dispatch(SET_PAGE_LOADER(true));
      loadParentCategory();
    }, []);  

  

  return (
    auth ? <>
      <Layout>
        <div>
          <div className='lg:px-24 gap-10'>
            <div className='w-full bg-white rounded-md border shadow' >
              <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                <h3>{formTitle}</h3>
                <Link to={'/product/subcategory'}>
                  <button className='button__bg px-6 py-2 block rounded'>Category Lists</button>
                </Link>
              </div>
              <form onSubmit={onSubmit} className='py-5 px-8'>
                <div className=''>

                  <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                    <div className="w-full mb-6">
                      <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Category Name</label>
                        <input type="text"
                          name='category_name'
                          value={form.category_name}
                          onChange={handleChange}
                          className="form-field" required />
                      </div>

                      <div className="w-full mt-8">
                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Image</label>
                        <input type="file" className='border-2 rounded py-1 px-1 w-full'
                          onChange={handleChange}
                          onClick={e => (e.target.value = null)}
                        />
                      </div>

                      {/* <div className="my-4">
                                    <label className="form-label">Show SearchBar</label>
                                    <select name='show_searchbar' value={form.show_searchbar} onChange={handleChange} className="select-field">
                                        <option  value="" selected  >Select</option>
                                        <option value="1">Show</option>
                                        <option value="2">Hide</option>
                                    </select>
                        </div>  */}

                             <div className="my-4">
                                    <label className="form-label">Parent Category</label>
                                    <select name='parent_category_id' value={form.parent_category_id} onChange={handleChange} className="select-field" required >
                                        <option  value="" selected  >Select</option>
                                       
                                        {category.map(item =>{
                                            return <option key={item} value={item.id}>{item.category_name}</option>
                                        })}
                                        
                                    </select>
                                </div> 
                    </div>

                    <div className="my-5 flex justify-end ">

                      {image.name ?
                        <img src={URL.createObjectURL(image)} alt="img" className="border-2 post__image " />
                        : <div>
                          {form.category_image &&
                            <img src={imagePath + showimage} alt="img" className="border-2 post__image " />
                          }
                        </div>}
                    </div>

                  </div>



                  <div className="mb-6">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Description</label>
                    <textarea id="txtid" className="form-field" rows="10" cols="50" maxlength="200" name='category_description'
                      value={form.category_description}
                      onChange={handleChange}
                    />
                  </div>

                  <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </Layout>
    </> : <Navigate to="/login" />
  );
};

export default EditProductSubCategory;