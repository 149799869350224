import { React, useEffect, useState } from 'react';
import baseApiUrl from '../../utility/baseApiUrl';

import Swal from 'sweetalert2';
import { Navigate } from 'react-router-dom';
import { SET_PAGE_LOADER } from "../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Layout from '../../components/Layout/Layout';
import Moment from 'react-moment';
import useAuth from '../../hooks/useAuth';
import Pagination from "react-js-pagination";

const axios = require("axios");

const Newsletter = () => {

  const [newslatter, setNewslatter] = useState([]);
  const dispatch = useDispatch();
  const auth = useAuth();

  // load newslatter from server
  const loadData = (pageNumber = 1) => {
    
    dispatch(SET_PAGE_LOADER(true));

    axios
      .get(baseApiUrl() + "newsletter?page="+pageNumber)
      .then((res) => {
        setNewslatter(res.data);
        dispatch(SET_PAGE_LOADER(false));
      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));
        console.log('server error', err);
        alert('Please reload again');
      })
  };


  useEffect(() => {

    loadData();
  }, []); 




  // delete newslatter
  const deleteItem = id => {
    // console.log(form)
    
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        dispatch(SET_PAGE_LOADER(true));
        
        axios.delete(baseApiUrl() + 'newsletter/' + id).then(res => {

          if (res.data.msg) {
            Swal.fire(
              'Deleted!',
              'Delete Success.',
              'success',
            )
            loadData()
          }

          else {
            dispatch(SET_PAGE_LOADER(false));

            Swal.fire(
              'Error!',
              'Server error try again.',
              'error',
            )
          }

        })
      }
    })
  }

  return (
    <Layout>
      <div className='bg-white rounded-lg '>

        <div className='px-4 py-3 mb-5 card__header border-2 text-md flex items-center justify-between '>
          <h3>Newsletter List</h3>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                 Subscribe Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className='mt-5'>
              {newslatter?.data?.map(item => {
                return (
                  <tr key={item} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.name}
                    </th>
                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {item.email}
                    </th>
                    <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                       <Moment date={item.created_at} format="D MMM YYYY" />
                    </th>
                    <td className="px-6 py-4 flex gap-5 text-2xl">
                      <span onClick={() => deleteItem(item.id)} className=' cursor-pointer'> <AiOutlineDelete /> </span>
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>

          {newslatter?.total > 5 && 
                <div className='pl-3 py-4'>
                    <Pagination
                        activePage={newslatter?.current_page ? newslatter?.current_page : 0}
                        itemsCountPerPage={newslatter?.per_page ? newslatter?.per_page : 0 }
                        totalItemsCount={newslatter?.total ? newslatter?.total : 0}
                        onChange={(pageNumber) => {
                          loadData(pageNumber)
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        itemClassFirst="p-first-page-link"
                        itemClassLast="p-last-page-link"
                        activeClass="p-active"
                        activeLinkClass="p-active-link"
                        firstPageText="First Page"
                        lastPageText="Last Lage"
                    />
                </div>
              }
        </div>
      </div>
    </Layout>
  );
};

export default Newsletter;