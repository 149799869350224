import { React, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../components/Layout/Layout';
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction";
import baseApiUrl from '../../../utility/baseApiUrl';
import formatCurrency from '../../../utility/formatCurrency';
import productTitleTrim from '../../../utility/productTitleTrim';

const axios = require("axios");

const EditOrder = () => {

  const [formTitle] = useState('Change Order Status');
  const [order, setOrder] = useState([]);
  const [orderItem, setOrderItem] = useState([]);
  const [subtotal, setSubtotal] = useState('');
  const [showVariation, setShowVariation] = useState({});

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.pageLoaderReducer);

  let { id } = useParams();


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

   const [form, setForm] = useState({
    status : '',
    });

    const handleChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value })
     }


  // load category from server
  const loadCategory = () => {
    axios

    //    .get('http://2-pakbettv-serverside.test/api/order/'+id).then(res => {
      .get(baseApiUrl() + "user/order/"+id)
      .then((res) => {

        const orderInfo = res.data.order;
        setOrder(orderInfo);
        
        let status = res.data.order.status;
        
        setForm({ status : status })

        setOrderItem(res.data.orderitem);

        dispatch(SET_PAGE_LOADER(false));

      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));

        console.error(err)
      })
    console.log('test-5', isLoading)
  };


  useEffect(() => {

    dispatch(SET_PAGE_LOADER(true));
    loadCategory();
 }, []); 


  const onSubmit = e =>{

    dispatch(SET_PAGE_LOADER(true));

    e.preventDefault();
 
    // axios.post('http://2-pakbettv-serverside.test/api/order/status-change/'+id, form).then(res => {
    axios.post(baseApiUrl() + 'order/status-change/'+id, form).then(res => {
       
        console.log(res)
        
        Toast.fire({
            icon: 'success',
            title: res.data.msg
          });

        dispatch(SET_PAGE_LOADER(false));

    }).catch((err) => {
        
        console.log(err);

        dispatch(SET_PAGE_LOADER(false));

        Toast.fire({
            icon: 'error',
            title: 'server error'
        });

    });

 }



  return (
    <Layout>
        <div className='lg:flex gap-5'>

            {/* left component */}
            <div className='lg:w-8/12 bg-white rounded-md border shadow' >

                <div className='px-4 py-4 mb-5 card__header border-2 text-md flex items-center justify-between '>
                    <h3>Order info</h3>
                    <Link to={'/orders'}>
                      <span className='button__bg px-6 py-3 rounded'>Order Lists</span>
                    </Link>
                </div>

                   <div className='px-4'>
                        
                        <div className="relative overflow-x-auto">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                      Product
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Price
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Quantity
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                    Total
                                    </th>
                                </tr>
                                </thead>
                                <tbody className='mt-5'>
                                {orderItem.map(item => {
                                    return (
                                    <tr key={item} className="bg-white border-b text-black dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                            {productTitleTrim(item.product_name)}   <br/>
                                          
                                            {(JSON.parse(item.variation))?.product_id && 
                                                 <>
                                                  <button className='mt-0.5 text-blue-600' onClick={() =>setShowVariation((JSON.parse(item.variation)))} >Show Variation</button>
                                                </>
                                            }

                                        </th>
                                        <td className="px-6 py-4">
                                        {formatCurrency(item.price)}
                                        </td>
                                        <td className="px-6 py-4">
                                        {item.quantity}
                                        </td>
                                        <td className="px-6 py-4">
                                        {formatCurrency(item.price * item.quantity)}
                                        </td>
                                    </tr>
                                    )
                                })}

                                </tbody>
                            </table>

                            <div className='px-4 mt-3 border-t-2 flex justify-between '>
                                <p className='mt-2'>Shipping Fee </p>
                                <p className='mt-2'>{formatCurrency(order.shipping_fee)}</p>
                            </div>

                            <div className='px-4 mt-3 border-t-2 flex justify-between '>
                                <p className='mt-2'>Subtotal </p>
                                <p className='mt-2'>{formatCurrency(order.subtotal)}</p>
                            </div>

                            {order.discount_price && 
                                <div className='px-4 mt-3 border-t-2 flex justify-between '>
                                    <p className='mt-2'>Discount Price </p>
                                    <p className='mt-2'>{formatCurrency(order.discount_price)}</p>
                                </div>
                            }

                        </div>

                        {showVariation?.product_id && 
                             <div className='border-2 rounded-md shadow-md px-5 py-3 mt-5'>
                                <div className='flex justify-between items-center '>
                                   <h1 className='text-bold'>Product Variation</h1>
                                   <button onClick={() =>setShowVariation({})} className='text-lg text-red-600'>X</button>
                                </div>

                                <div className='mt-2 pt-2 border-t-2'>
                                    {/* {showVariation?.attributes} */}
                                    {(JSON.parse(showVariation.attributes))?.map(itemAttribute=> `  ${itemAttribute.name}  :  ${itemAttribute.attribute_item}` )} 

                                </div>

                            </div>
                        }

                        <div className='border-t-2 pb-8 px-4 mt-8'>
                            <h1 className='mt-3 text-lg'>Shipping Address</h1>
                            <div className="mt-5 flex flex-wrap gap-10">
                                <p>Tracking Id : #{order.id}</p>
                                <p>First Name : {order.first_name}</p>
                                <p>last Name : {order.last_name}</p>
                            </div>
                            <div className="my-4 py-4 border-y-2 flex flex-wrap gap-10">
                                <p>Phone : #{order.phone_number}</p>
                                <p>Email : {order.email_address}</p>
                            </div>
                            <div className="my-4 py-4 border-y-2 flex flex-wrap gap-10">
                                <p>Company  Name : {order.company_name}</p>
                                <p>Street Address : {order.street_address}</p>
                            </div>
                            <div className="my-4 py-4 border-y-2 flex flex-wrap gap-10">
                                <p>Province : {order.province}</p>
                                <p>City Municipality : {order.city_municipality}</p>
                            </div>
                            <div className="my-4 py-4 border-y-2">
                                <p>Other Location : {order.other_location}</p>
                            </div>
                            <div className="my-4 py-4">
                                <p>Order Note : {order.order_note}</p>
                            </div>
                        </div>
                    </div>
            </div>

            {/* right component    */}
            <div className='phone-mt lg:w-4/12'>
                <div className='w-full bg-white rounded-md border shadow' >
                    <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                        <h3 className="py-2">{formTitle}</h3>
                    </div>

                    <div className='px-4'>
                       
                        <div className='flex gap-4'>
                            <p>Payment Method:</p>
                            <p className='text-sm font-bold'>
                                {order.payment_method == '1' && 'Dragonpay'}
                                {order.payment_method == '4' && 'Cash on delivery'}
                            </p>
                        </div>

                        {order.payment_method == '4' ? <div>

                            <div className='flex gap-4'>
                                <p>Payment status:</p>
                                <p className='text-sm font-bold'>
                                   Cash on delivery
                                    {/* {order.pay_status ?'Cash on delivery' : 'Pending'} */}
                                </p>
                            </div>
                             
                         </div> : (
                            
                            <div className='mt-5'>
                                <div className='flex gap-4'>
                                <p>Payment status:</p>
                                <p className='text-sm font-bold'>
                                    {order.pay_status}
                                </p>
                                </div>

                                {order.pay_date  &&

                                <div className='mt-5 flex gap-4'>
                                    <p>Payment Date</p>
                                    <p className='text-sm font-bold'>
                                      <Moment date={order.pay_date} format="D MMM YYYY" />
                                    </p>
                                </div>
                                }
                            </div>
                         )}

                    </div>

                       <form onSubmit={onSubmit} >
                          
                            <div className="pt-3 my-4 px-4">
                                <label  className='mt-3 border-t py-3 text-lg'>Change Status</label>
                                <select 
                                    name='status'
                                    value={form.status}
                                    onChange={handleChange} 
                                    className="mt-3 select-field"
                                 >
                                    <option value="0" >Pending
                                    </option>
                                    <option value="1" >Failed
                                    </option>
                                    <option value="2" >Processing</option>
                                    <option value="3" >On hold
                                    </option>
                                    <option value="4" >Pending payment
                                    </option>
                                    <option value="5" >Cancelled
                                    </option>
                                    <option value="6">Complated
                                    </option>
                                    <option value="7">Refunded
                                    </option>
                                </select>


                            <div className='mt-5 '>
                                <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
      </Layout>
   );
};

export default EditOrder;