import React, { useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Pagination from "react-js-pagination";
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../components/Layout/Layout';
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction";
import baseApiUrl from '../../../utility/baseApiUrl';

import localBaseApiUrl from '../../../utility/localBaseApiUrl';
const axios = require("axios");

const AttributeItem = () => {

    const dispatch = useDispatch();
    let { id } = useParams();
    
    const [isEditData, setIsEditData] = useState({});

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    const [attribute, setAttribute] = useState({});
    const [attributeItems, setAttributeItem] = useState({});

    const [form, setForm] = useState({
        attribute_item : '',
        attribute_id : id,
    });

    const editData = item =>{
       
        setIsEditData(item);

        setForm({
            attribute_item : item.attribute_item,
        });

     }

    const resetForm = () =>{
        setForm({
            attribute_item : '',
            attribute_id : id,
        });

        setIsEditData({});
    }


    const handleChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value })
        
     }
     

     const onSubmit = e =>{

        dispatch(SET_PAGE_LOADER(true));

        e.preventDefault();

        if(isEditData?.id){

            axios.put(baseApiUrl()+'attribute-item/'+isEditData?.id, form).then(res => {
            // axios.post(baseApiUrl() + 'attribute-item', data).then(res => {
               
                loadAttributeItems();
                
                Toast.fire({
                    icon: 'success',
                    title: res.data.msg
                  });
    
                  dispatch(SET_PAGE_LOADER(false));
    
                  resetForm();
    
            }).catch((err) => {
                loadAttributeItems();
                console.log(err);
                dispatch(SET_PAGE_LOADER(false));
                Toast.fire({
                    icon: 'error',
                    title: 'Try again'
                  });
            });

        }else{

            axios.post(baseApiUrl()+'attribute-item', form).then(res => {
            // axios.post(baseApiUrl() + 'attribute-item', data).then(res => {
               
                console.log('attribute_res', res)
                
                Toast.fire({
                    icon: 'success',
                    title: res.data.msg
                  });
    
                  dispatch(SET_PAGE_LOADER(false));
    
                  resetForm();
    
            }).catch((err) => {
                console.log(err);
                dispatch(SET_PAGE_LOADER(false));
                Toast.fire({
                    icon: 'error',
                    title: 'Try again'
                  });
            });
        }


     }


        const loadAttributeItems = (pageNumber = 1) => {
            
            dispatch(SET_PAGE_LOADER(true));

            axios
            .get(baseApiUrl()+`attribute-items/${id}?page=`+pageNumber)
            // .get(baseApiUrl() + "coupon?page="+pageNumber)
            .then((response) => {
                
                dispatch(SET_PAGE_LOADER(false));
                setAttributeItem(response.data);
                
            })
            .catch((err) => {
                dispatch(SET_PAGE_LOADER(false));
                console.log('product data server error', err);
                alert('Please reload again');
            })
        };

    useEffect(() => {
        loadAttributeItems();

    }, []);  

     // delete attribute
     const deleteData = id => {
        // console.log(form)

        Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
        if (result.isConfirmed) {

            dispatch(SET_PAGE_LOADER(true));

            axios.delete(baseApiUrl()+'attribute-items/' + id).then(res => {
            if (res.data.msg) {
                
                Swal.fire(
                'Deleted!',
                'Delete Success.',
                'success',
                )
                loadAttributeItems();
                dispatch(SET_PAGE_LOADER(false));
            }

            else {
                dispatch(SET_PAGE_LOADER(false));
                Swal.fire(
                'Error!',
                'Server error try again.',
                'error',
                )
            }

            })
        }
        })
    }


    return (
        <Layout>
            <div className='lg:flex'>

                <div className='lg:px-5 lg:w-5/12 '>
                    <div className='w-full bg-white rounded-md border shadow' >
                    <div className='px-4 py-3 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                        <h3>Attribute</h3>
                        <Link to={'/attributes'}>
                            <button className='button__bg px-6 py-2 block rounded'>Attribute Lists</button>
                        </Link>
                    </div>
                    <form onSubmit={onSubmit} className='py-5 px-8'>
                        <div className=''>
                                <div className="w-full mb-6">
                                        <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Add new attribute</label>
                                        <input type="text"
                                        name='attribute_item'
                                        value={form.attribute_item}
                                        onChange={handleChange}
                                        className="form-field" required />
                                        </div>
                                    </div>
                                </div>

                            <div className='flex flex-wrap gap-3 '>
                                    <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
                                    <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p>
                            </div>
                    </form>
                    </div>
                </div>

                <div className='lg:px-5 lg:w-7/12'>
                    <div className='w-full bg-white rounded-md border shadow' >
                    <div className='px-4 py-3 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                        <h3>Attribute Lists</h3>
                    </div>

                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                <th scope="col" className="pl-6 py-3  whitespace-nowrap">
                                  Attributes
                                </th>
                                {/* <th scope="col" className="px-4 py-3  whitespace-nowrap">
                                   Attribute Slug
                                </th> */}
                                <th scope="col" className="pl-6 py-3  whitespace-nowrap">
                                    Action
                                </th>
                                </tr>
                            </thead>
                            <tbody className='mt-5'>
                                {attributeItems?.data?.map(item => {
                                return (
                                    <tr key={item} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                  
                                    <th scope="row" className="attributes-th pl-6 py-4 font-medium text-gray-900 dark:text-white items-start ">
                                      {item.attribute_item} 
                                    </th>

                                    <td className="px-5 py-4 mt-4 text-sm">
                                         <div className='mt-2 text-lg flex gap-3 '>
                                           <button onClick={() => deleteData(item.id)} ><AiOutlineDelete /></button>
                                           <button onClick={() =>editData(item)} ><AiOutlineEdit /></button>
                                         </div>
                                    </td>
                                    </tr>
                                )
                                })}

                            </tbody>
                        </table>

                        {attributeItems?.total > 10 && 
                            <div className='pl-3 py-4'>
                                <Pagination
                                    activePage={attributeItems?.current_page ? attributeItems?.current_page : 0}
                                    itemsCountPerPage={attributeItems?.per_page ? attributeItems?.per_page : 0 }
                                    totalItemsCount={attributeItems?.total ? attributeItems?.total : 0}
                                    onChange={(pageNumber) => {
                                     loadAttributeItems(pageNumber)
                                    }}
                                    pageRangeDisplayed={8}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    itemClassFirst="p-first-page-link"
                                    itemClassLast="p-last-page-link"
                                    activeClass="p-active"
                                    activeLinkClass="p-active-link"
                                    firstPageText="First Page"
                                    lastPageText="Last Lage"
                                />
                            </div>
                        }
                        </div>
                        
                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default AttributeItem;