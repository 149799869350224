import React from "react";
import { AiOutlineBell, AiOutlineMenu } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import './style.css';
const TopNavBar = () => {
   const dispatch = useDispatch();
   const handleLogoutOnClik = () => {
      localStorage.clear();
      // logout();
      dispatch({
        type: "login_status_update",
        payload: false
      })
      dispatch({
        type: "loggedinUserInfo_update",
        payload: {}
      })
      dispatch({
        type: "tokenValue_update",
        payload: ""
      })
      //     alert("hello")
  
      // dispatch(loggedInUser(true))
      // setOpen(false);
    };
   function loadBody(){

      // const menuicon = document.getElementById("menuicon");
      // const phone_menu_icon = document.getElementById("phone_menu_icon");

      const sidebar = document.getElementById("sidebar");

      // const activeMenu = document.querySelector(".active__menu");
      
      // menuicon.addEventListener("click", function() {
        sidebar.classList.toggle('toggle__Sidebar');
      // });

      // phone_menu_icon.addEventListener("click", function() {
      //   sidebar.classList.toggle('toggle__phone__Sidebar');
      // });
  }
   function loadBodyMobile(){

      // const menuicon = document.getElementById("menuicon");
      // const phone_menu_icon = document.getElementById("phone_menu_icon");

      const sidebar = document.getElementById("sidebar");

      // const activeMenu = document.querySelector(".active__menu");
      
      // menuicon.addEventListener("click", function() {
      //   sidebar.classList.toggle('toggle__Sidebar');
      // });

      // phone_menu_icon.addEventListener("click", function() {
        sidebar.classList.toggle('toggle__phone__Sidebar');
      // });
  }
  return (
    <div className="bg-white flex justify-between px-5 py-6">
      <div className="flex justify-between lg:gap-28 md:gap-16 gap-8 items-center ">
            
         {/* logo */}
         <div className="  ">
           <Link to={'/'}>
               <img width="85px" height="60px" src="https://pakbettv.com/wp-content/uploads/2021/06/Logo-small-3.png" alt="PAKBET TV" />
               </Link>
         </div>


         <div className="flex items-center gap-5">
           <div onClick={loadBody} id="menuicon" className=" lg:block md:block  hidden  text-lg cursor-pointer light__bg rounded-md p-2">
               <AiOutlineMenu />
           </div>

           <div onClick={loadBodyMobile} id="phone_menu_icon" className="lg:hidden md:hidden  block text-lg cursor-pointer light__bg rounded-md p-2">
               <AiOutlineMenu />
           </div>
        

           {/* search box */}
           <div className="lg:block md:block  hidden "> 
               <div className="flex items-center border-2 rounded-lg">
                  <button className="flex items-center justify-center px-4 border-r">
                        <svg className="w-6 h-6 text-gray-600" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                           viewBox="0 0 24 24">
                           <path
                              d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z">
                           </path>
                        </svg>
                  </button>
                  <input type="text" className="px-4 py-2 block" placeholder="Search..." />
               </div>
            </div>
         </div>

      </div>

       <div className="flex items-center gap-5">
           <div className="light__bg rounded-md p-2">
              <AiOutlineBell />
           </div>
           <button onClick={handleLogoutOnClik} className="light__bg rounded-md p-2">
            
              <FiLogOut />
           </button>

         <div>
            <img src="https://berrydashboard.io/static/media/user-round.13b5a31b.svg" alt="" />
          </div>
       </div>
    </div>
  );
};

export default TopNavBar;
