import { React, useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../components/Layout/Layout';
import useAuth from '../../../hooks/useAuth';
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction";
import baseApiUrl from '../../../utility/baseApiUrl';
import formatCurrency from '../../../utility/formatCurrency';

import Pagination from "react-js-pagination";

const axios = require("axios");

const Orders = () => {

  const [order, setOrder] = useState([]);
  const auth = useAuth();

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.pageLoaderReducer);


  // load order from server
  const loadData = (pageNumber = 1) => {
  
    dispatch(SET_PAGE_LOADER(true));

    axios

      //  .get('http://2-pakbettv-serverside.test/api/orders')
      .get(baseApiUrl() + "orders?page="+pageNumber)
      .then((res) => {
        setOrder(res.data);

        dispatch(SET_PAGE_LOADER(false));

      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));

        console.error(err)
      })
     

    console.log('test-5', isLoading)
  };


  useEffect(() => {
    loadData();
  }, []); 


  // delete order
  const deleteOrder = item => {
    // console.log(form)

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        dispatch(SET_PAGE_LOADER(true));
        
           axios.delete(baseApiUrl() + 'order/delete/' + item.id).then(res => {
           
            dispatch(SET_PAGE_LOADER(false));

            Swal.fire(
              'Deleted!',
              res.data.msg,
              'success',
            )
            loadData();
        })
      }
    })
  }

  return (
    <Layout>
      <div className='bg-white rounded-lg '>

        <div className='px-4 py-3 mb-5 card__header border-2 text-md flex items-center justify-between '>
          <h3>Orders List</h3>
        </div>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Order Id
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Data
                  </th>
                  <th scope="col" className="px-6 py-3">
                  Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                  Subtotal
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className='mt-5'>
                {order?.data?.map(item => {
                  return (
                    <tr key={item} className="bg-white border-b text-black dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        #{item.id}
                      </th>
                      <td className="px-6 py-4">
                        {item.first_name}
                      </td>
                      <td className="px-6 py-4">
                         <Moment date={item.created_at} format="D MMM YYYY" />
                      </td>
                      <td className="px-6 py-4">
                          {item.status === 0 && <p>Pending</p>}
                          {item.status === 1 && <p>Failed</p>}
                          {item.status === 2 && <p>Processing</p>}
                          {item.status === 3 && <p>On hold</p>}
                          {item.status === 4 && <p>Pending payment</p>}
                          {item.status === 5 && <p>Cancelled</p>}
                          {item.status === 6 && <p>Complated</p>}
                          {item.status === 7 && <p>Refunded</p>}
                      </td>
                      <td className="px-6 py-4">
                        {formatCurrency(item.subtotal)}
                      </td>
                      <td className="px-6 py-4 flex gap-5 text-2xl">
                        <span onClick={() => deleteOrder(item)} className=' cursor-pointer'> <AiOutlineDelete /> </span>
                        <Link to={`/order/edit/${item.id}`}><AiOutlineEdit /></Link>
                      </td>
                    </tr>
                  )
                })}

              </tbody>
            </table>

             {order?.total > 5 && 
              <div className='pl-3 py-4'>
                  <Pagination
                      activePage={order?.current_page ? order?.current_page : 0}
                      itemsCountPerPage={order?.per_page ? order?.per_page : 0 }
                      totalItemsCount={order?.total ? order?.total : 0}
                      onChange={(pageNumber) => {
                          loadData(pageNumber)
                      }}
                      pageRangeDisplayed={8}
                      itemClass="page-item"
                      linkClass="page-link"
                      itemClassFirst="p-first-page-link"
                      itemClassLast="p-last-page-link"
                      activeClass="p-active"
                      activeLinkClass="p-active-link"
                      firstPageText="First Page"
                      lastPageText="Last Lage"
                  />
              </div>
             }
          </div>
      </div>
    </Layout>
  );
};

export default Orders;