import React, { useState, useRef, useEffect } from "react";
import Swal from 'sweetalert2';
import { Link, Navigate } from 'react-router-dom';
import baseApiUrl from '../../../utility/baseApiUrl';
import {SET_PAGE_LOADER} from "../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import Layout from '../../../components/Layout/Layout';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import useAuth from '../../../hooks/useAuth';

const axios = require("axios");
const CreateConsultation = () => {

    const dispatch = useDispatch();
    const auth = useAuth();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "mention"
      ];

      const   modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"]
        ]
      };

    const inputFileRef = useRef(null);

    const [consultation_description, setConsultationDescription] = useState("");


    const [formTitle] = useState('Create Consultation');


    const [image, setImage] = useState({}); // changeHere - stateName correction


    const [form, setForm] = useState({
        consultation_title: '',
        consultation_price: '',
    });

    const resetForm = () => {

        setForm({
            consultation_title: '',
            consultation_price: '',
        })

        setConsultationDescription("");

        inputFileRef.current.value = "";

        setImage({});
    }



    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })

        if (e.target.files[0]) {
            setImage(e.target.files[0])
        }
    };


    const ConsDesFunc = value => {
        setConsultationDescription(value);
    }


 

    const onSubmit = e => {

        e.preventDefault();

            dispatch(SET_PAGE_LOADER(true));

            let data = new FormData();
            data.append('consultation_title', form.consultation_title);
            data.append('consultation_price', form.consultation_price);
            
            data.append('consultation_description', consultation_description);

            if (image?.name) {
                data.append("consultation_image", image);
            }

            axios.post(baseApiUrl() + 'consultation', data).then(res => {

                console.log(res)

                Toast.fire({
                    icon: 'success',
                    title: res.data.msg
                });

                resetForm();

                dispatch(SET_PAGE_LOADER(false));

            }).catch((err) => {
                console.log(err);

                Toast.fire({
                    icon: 'error',
                    title: 'server error'
                });

                dispatch(SET_PAGE_LOADER(false));
            });
    }

    useEffect(() => {
    });

    return (
        <Layout>
            <form onSubmit={onSubmit}>
                <div className='lg:flex gap-5'>

                    {/* left component */}
                    <div className='lg:w-8/12 bg-white rounded-md border shadow' >

                        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                            <h3>{formTitle}</h3>
                            <button className='button__bg px-6 py-2 rounded'><Link to={'/consultations'}>Consultation Lists</Link>
                            </button>
                        </div>

                        <div className='py-5 px-8'>

                            <div className="w-full mb-6">
                                <label className="form-label">Consultation title</label>
                                <input type="text"
                                    name='consultation_title'
                                    value={form.consultation_title}
                                    onChange={handleChange}
                                    className="form-field" required />
                            </div>


                            <div className="mb-6">
                                <label className="form-label">Consultation Description</label>

                                <ReactQuill value={consultation_description}
                                onChange={ConsDesFunc} 
                                modules={modules}
                                formats={formats}
                                />

                                 {/* <textarea className="form-field" rows="10" cols="50" maxlength="200"  name='consultation_description' 
                                  value={form.consultation_description}
                                  onChange={handleChange}
                                 /> */}

                            </div>
                        </div>
                    </div>

                    {/* right component    */}
                    <div className='phone-mt lg:w-4/12'>
                        <div className='w-full bg-white rounded-md border shadow' >
                            <div className='px-4 py-4 mb-5 card__header border-2 text-md flex items-center justify-between '>
                                {/* <h3>{formTitle}</h3> */}
                                 <h1>Consultation</h1>
                            </div>
                            <div className='py-5 px-8'>
                           
                           
                               <div className="w-full mb-6">
                                    <label htmlFor="email" className="form-label">Consultation Price</label>
                                    <input type="number"
                                        name='consultation_price'
                                        value={form.consultation_price}
                                        onChange={handleChange}
                                        className="form-field" required />
                                </div>


                                <div className="w-full mb-6">
                               <div className=''>
                                   <div>
                                        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Consultation Image</label>
                                        <input  required type="file" ref={inputFileRef} className='border-2 rounded py-1 px-1 w-full'
                                            onChange={handleChange} 
                                        onClick={e => (e.target.value = null)}
                                        />
                                   </div>
                                   <div>
                                        {image.name && 
                                        <div className="mt-3">
                                            <img src={URL.createObjectURL(image)} alt="" srcset="img" className="h-30 shadow sobject-cover " />
                                            </div>
                                        }
                                   </div>
                               </div>
                               
                           </div>


                                <div className='flex flex-wrap gap-3 '>
                                    <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                                    <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </Layout>
    );
};

export default CreateConsultation;