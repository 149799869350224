import React, { useState, useRef  } from 'react';
import Swal from 'sweetalert2';
import { Link, Navigate } from 'react-router-dom';
import baseApiUrl from '../../utility/baseApiUrl';
import {SET_PAGE_LOADER} from "../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import './style.css'
import useAuth from "../../hooks/useAuth";

const axios = require("axios");

const CreateForecast = () => {

    const dispatch = useDispatch();
    const auth = useAuth();

    const ref = useRef();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    const [formTitle] = useState('Create Forecast');

    const [image, setImage] = useState({}); // changeHere - stateName correction

    const [horoscope_page_title, setHoroscopePageTitle] = useState('');
    const [sub_title, setSubTitle] = useState('');
    const [overview, setOverview] = useState('');
    const [wealth, setWealth] = useState('');
    const [career, setCareer] = useState('');
    const [love, setLove] = useState('');
    const [health, setHealth] = useState('');



    const resetForm = () =>{
        setHoroscopePageTitle('')
        setSubTitle('')
        setOverview('')
        setWealth('')
        setCareer('')
        setLove('')
        setHealth('')

        ref.current.value = "";
        setImage({})
    }


     const onSubmit = e =>{

        dispatch(SET_PAGE_LOADER(true));

        e.preventDefault();

        let data = new FormData();
        data.append('horoscope_page_title', horoscope_page_title);
        data.append('sub_title', sub_title);

        data.append('overview', overview);
        data.append('wealth', wealth);
        data.append('career', career);
        data.append('love', love);
        data.append('health', health);

        if(image?.name){
            data.append("featured_image", image);
          }   

        axios.post(baseApiUrl() + 'horoscope', data).then(res => {
                       
            Toast.fire({
                icon: 'success',
                title: res.data.msg
              });

              dispatch(SET_PAGE_LOADER(false));

              resetForm();

        }).catch((err) => {
            console.log(err);
            dispatch(SET_PAGE_LOADER(false));

            Toast.fire({
                icon: 'error',
                title: 'server error'
            });
        });

     }

    return (
        <Layout>
        <div>
        <div className='lg:px-24 gap-10'>
            <div className='w-full bg-white rounded-md border shadow' >
            <div className='px-4 py-2 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                   <h3>{formTitle}</h3>

                   <Link to={'/forecast'}>
                     <button className='button__bg px-6 py-2 block rounded'>Forecast Lists</button>
                   </Link>
            </div>
               <form onSubmit={onSubmit} className='py-5 px-8'>
                   <div className=''>

                        <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                            <div className="w-full mb-6">
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Title</label>
                                    <input type="text"
                                    value={horoscope_page_title}
                                    onChange={e => setHoroscopePageTitle(e.target.value)}
                                    className="form-field" required />
                               </div>

                               <div className='mt-5'>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Sub Title</label>
                                    <input type="text"
                                    value={sub_title}
                                    onChange={e => setSubTitle(e.target.value)}
                                    className="form-field" required />
                               </div>

                            <div className="w-full mt-8">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Feature Image</label>
                            <input type="file" ref={ref} required className='border-2 rounded py-1 px-1 w-full'
                                onChange={e => setImage(e.target.files[0])}
                                onClick={e => (e.target.value = null)}
                                />
                            </div>
                            </div>

                            <div className="my-5 flex justify-end ">
                                {image.name &&  
                                <img src={URL.createObjectURL(image)} alt="" className="border-2 post__image "/>
                                }
                            </div>

                        </div>

                        <div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Overview </label>
                            <input type="text"
                            value={overview}
                            onChange={e => setOverview(e.target.value)}
                            className="form-field" required />
                        </div>
                  

                        <div className='mt-5'>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Career </label>
                            <input type="text"
                            value={career}
                            onChange={e => setCareer(e.target.value)}
                            className="form-field" required />
                        </div>

                        <div className='mt-5'>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Health </label>
                            <input type="text"
                            value={health}
                            onChange={e => setHealth(e.target.value)}
                            className="form-field" required />
                        </div>

                        <div className='mt-5'>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Love </label>
                            <input type="text"
                            value={love}
                            onChange={e => setLove(e.target.value)}
                            className="form-field" required />
                        </div>

                        <div className='mt-5'>
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Wealth </label>
                            <input type="text"
                            value={wealth}
                            onChange={e => setWealth(e.target.value)}
                            className="form-field" required />
                        </div>

                       <div className='mt-8 flex flex-wrap gap-3 '>
                            <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                            <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p>
                       </div>
                   </div>
               </form>
            </div>

        </div>
      </div>
        </Layout>
    );
};

export default CreateForecast;