import { React, useEffect } from 'react';
import LoadingComponent from 'react-fullpage-custom-loader';
import { useSelector } from 'react-redux';
// import useAuth from './hooks/useAuth';
// import Dashboard from "./Pages/Dashboard/Dashboard";
import './index.css';
import Master from './pages/Master';
import "./responsive.css";

// import { useSelector } from 'react-redux';
// import Login from './pages/Login/Login';

function App() {

  const isLoading = useSelector((state) => state.pageLoaderReducer);

  // const [isLogin, setLogin] = useState(false);
  // let isLoggedIn = useAuth()

  useEffect(() => {

    if (isLoading) {
      console.log('isLoading', isLoading)
    } else {
      console.log('isLoading', isLoading)
    }

  });


  return (
    <div className="App m-0 p-0 ">
            {isLoading && 
              <div className='popub__bg bg-[#0000006e] w-full max-h-screen '>
                  <LoadingComponent sentences={'.'} />
                  <h1>hello</h1>
              </div>
            }
      {/* <LoadingComponent sentences={'.'}/> */}
      {/* before edit  */}
      {/* <TopNavBar></TopNavBar> */}
      {/* <Routes>
      <Route path="/login" element={<Login />} />
      </Routes> */}

      <div>

        <div>
          <Master />
        </div>


      </div>
      {/* before edit//  */}

      {/* <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="create/product/category" element={<CreateProductCategory />} />
        
       
        <Route  path="*" element={<NoPageFound />}  />
      </Routes> */}

    </div>
  );
}

export default App;
