import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import useAuth from '../../hooks/useAuth';
import { useDispatch } from 'react-redux';

const Dashboard = () => {
   
    const auth = useAuth();
    const dispatch = useDispatch();

    useEffect( () =>{
    })

    return (
        auth ? <>
            <div>
                <Layout>
                    <div className="main bg-white border-2 border-gray-300 rounded">
                        <div className="rounded-t border-b-2 border-gray-300" style={{ backgroundColor: '#F0F3F5' }}>
                            <h1 className="p-4">Dashboard</h1>
                        </div>
                        <div className="p-4">
                            <p className="mb-4"> Welcome to Pakbettv Adminstration.</p>
                            <hr />
                           
                        </div>

                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                </Layout>
            </div>
        </>:  <Navigate to="/login" />
    );
};

export default Dashboard;