import { React, useEffect, useState } from 'react';
import baseApiUrl from '../../../../utility/baseApiUrl';
import Swal from 'sweetalert2';
import { Link, Navigate } from 'react-router-dom';
import { SET_PAGE_LOADER } from "../../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import apiStoragePath from '../../../../utility/apiStoragePath';
import Layout from '../../../../components/Layout/Layout';
import formatCurrency from '../../../../utility/formatCurrency';
import Pagination from "react-js-pagination";

const axios = require("axios");

const Coupon = () => {
  const [product, setproduct] = useState({});
  const dispatch = useDispatch();
  const imagePath = apiStoragePath();

  // load product from server
  const loadCoupon = (pageNumber = 1) => {
    
    dispatch(SET_PAGE_LOADER(true));

    axios
      .get(baseApiUrl() + "coupon?page="+pageNumber)
      .then((response) => {
        
        dispatch(SET_PAGE_LOADER(false));
        
        if(response.status >= 200 && response.status < 300){
          setproduct(response.data);
          console.log('test_8080')
        }
        
      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));
        console.log('product data server error', err);
        alert('Please reload again');
      })
  };

  useEffect(() => {
    loadCoupon();
  }, []);  



  // delete product
  const deleteCoupon = id => {
    // console.log(form)

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        dispatch(SET_PAGE_LOADER(true));

        axios.delete(baseApiUrl() + 'coupon/' + id).then(res => {
         
          dispatch(SET_PAGE_LOADER(false));

          if (res.data.msg) {
            
            Swal.fire(
              'Deleted!',
              'Delete Success.',
              'success',
            )

            loadCoupon();
          }

          else {
            dispatch(SET_PAGE_LOADER(false));

            Swal.fire(
              'Error!',
              'Server error try again.',
              'error',
            )
          }

        })
      }
    })
  }

  return (
      <Layout>
        <div className='bg-white rounded-lg'>

          <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
            <h3>Coupon List</h3>

              <Link to={'/create/coupon'}>
                      <button className='button__bg px-6 py-2 block rounded'>Add Coupon</button>
              </Link>

          </div>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                     Coupon Code
                  </th>
                  <th scope="col" className="px-6 py-3">
                     Coupon Type
                  </th>
                  <th scope="col" className="px-6 py-3">
                     Coupon Amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className='mt-5'>
                {product?.data?.map(item => {
                  return (
                    <tr key={item} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {item.coupon_code}
                      </th>
                      <th className="px-6 py-4 text-black dark:text-white whitespace-nowrap">
                        {item.type === '2'  ? 'Percentage' : 'Fixed'}
                      </th>
                      <td className="px-6 py-4 text-black text-md ">

                        {item.type === '2' ? <span>{item.amount} % </span> : <span>{formatCurrency(item.amount)}</span>}
                        
                      </td>
                      <td className="px-6 py-4 mt-4 flex gap-5 text-2xl">
                        <span onClick={() => deleteCoupon(item.id)} className=' cursor-pointer'> <AiOutlineDelete /> </span>
                        <Link to={`/edit/coupon/${item.id}`}> <AiOutlineEdit /></Link>
                      </td>
                    </tr>
                  )
                })}

              </tbody>
            </table>

            {product?.total > 5 && 
                <div className='pl-3 py-4'>
                    <Pagination
                        activePage={product?.current_page ? product?.current_page : 0}
                        itemsCountPerPage={product?.per_page ? product?.per_page : 0 }
                        totalItemsCount={product?.total ? product?.total : 0}
                        onChange={(pageNumber) => {
                          loadCoupon(pageNumber)
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        itemClassFirst="p-first-page-link"
                        itemClassLast="p-last-page-link"
                        activeClass="p-active"
                        activeLinkClass="p-active-link"
                        firstPageText="First Page"
                        lastPageText="Last Lage"
                    />
                </div>
              }
          </div>
        </div>
      </Layout>
  );
};

export default Coupon;