import { React, useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout/Layout';
import { SET_PAGE_LOADER } from "../../redux/actions/loaderAction";
import baseApiUrl from '../../utility/baseApiUrl';
import './style.css';
import Pagination from "react-js-pagination";
import apiStoragePath from '../../utility/apiStoragePath';

import useAuth from "../../hooks/useAuth";

const axios = require("axios");

const Forecast = () => {
  const imagePath = apiStoragePath();

  const [category, setForecasts] = useState([]);
  const auth = useAuth();

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.pageLoaderReducer);


  // load category from server
  const loadForecast = (pageNumber = 1) => {

    dispatch(SET_PAGE_LOADER(true));

    axios
      .get(baseApiUrl() + "horoscope?page="+pageNumber)
      .then((res) => {
        setForecasts(res.data);

        dispatch(SET_PAGE_LOADER(false));

      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));
        console.log('server error', err);
        alert('Please reload again');
      })
  };


  useEffect(() => {

    loadForecast();
  }, []); 




  // delete category
  const deleteCategory = item => {
    // console.log(form)

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        dispatch(SET_PAGE_LOADER(true));

        axios.delete(baseApiUrl() + 'horoscope/' + item.id).then(res => {

            Swal.fire(
              'Deleted!',
              res.data.msg,
              'success',
            )
            loadForecast();
        })
      }
    })
  }

  return (
    <Layout>
      <div className='bg-white rounded-lg '>

        <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
          <h3>Forecast List</h3>
              <Link to={'/create/forecast'}>
                     <button className='button__bg px-6 py-2 block rounded'>Add Forecast</button>
              </Link>
        </div>

        <div className="relative overflow shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Image
                </th>
                <th scope="col" className="px-6 py-3">
                  Title
                </th>
                <th scope="col" className="px-6 py-3">
                  Sub Title
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className='mt-5'>
              {category?.data?.map(item => {
                return (
                  <tr key={item} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      <img src={imagePath+item.featured_image} alt="" className="h-12 border-2 "/>
                    </th>
                    <td className="px-6 py-4">
                      {item.horoscope_page_title}
                    </td>
                    <td className="px-6 py-4">
                      {item.sub_title}
                    </td>
                    <td className="px-6 py-4 flex gap-5 mt-2 text-2xl">
                      <span onClick={() => deleteCategory(item)} className=' cursor-pointer'> <AiOutlineDelete /> </span>
                      <Link to={`/edit/forecast/${item.id}`}><AiOutlineEdit /></Link>
                    </td>
                  </tr>
                )
              })}

            </tbody>
          </table>

           {category?.total > 5 && 
                <div className='pl-3 py-4'>
                    <Pagination
                        activePage={category?.current_page ? category?.current_page : 0}
                        itemsCountPerPage={category?.per_page ? category?.per_page : 0 }
                        totalItemsCount={category?.total ? category?.total : 0}
                        onChange={(pageNumber) => {
                          loadForecast(pageNumber)
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        itemClassFirst="p-first-page-link"
                        itemClassLast="p-last-page-link"
                        activeClass="p-active"
                        activeLinkClass="p-active-link"
                        firstPageText="First Page"
                        lastPageText="Last Lage"
                    />
                </div>
              }
        </div>
      </div>
    </Layout>
  );
};

export default Forecast;