import React from "react";
import { AiOutlineDashboard } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import './style.css';

const Sidebar = () => {
  return (
    <div className='px-4 bg-white h-screen '>

      <div className="mt-5">
        <p>Dashboard</p>

        <NavLink to="/" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Dashboard
        </NavLink>

        {/* <p className=' flex menu_link items-center gap-4'>
                   <span className="menu__icon"><span className="menu__icon"><AiOutlineDashboard/></span></span> Setting
                </p> */}
      </div>

      <div className="my-3 border-y py-5">
        <p>User Management</p>

          <NavLink to="/users" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard/></span> 
                 User Lists
          </NavLink>

            <NavLink to="/create/user" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Add User
        </NavLink>
      </div>

      <div className="my-3 border-y py-5">
        <p>Ecommerce Management</p>

          <NavLink to="/orders" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard/></span> 
                 Order Lists
          </NavLink>

          <NavLink to="/product/review" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard/></span> 
                 Product Review
          </NavLink>
          <NavLink to="/product/shpping-fee" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard/></span> 
              Shpping Fee
          </NavLink>
          <NavLink to="/coupon" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard/></span> 
              Coupon
          </NavLink>
      </div>

      <div className="my-3 border-y py-5">
        <p>Product Management</p>
        <NavLink to="/create/product/category" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Add Category
        </NavLink>

        <NavLink to="/product/category" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Category Lists
        </NavLink>

        <NavLink to="/product/subcategory" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Sub-Category Lists
        </NavLink>

          <NavLink to="/products" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard/></span> 
                 Product Lists
          </NavLink>

            <NavLink to="/create/product" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard /></span>
              Add Product
           </NavLink>
            <NavLink to="/attributes" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard /></span>
              Attributes
           </NavLink>

      </div>

      <div className="border-b py-5">
        <p>Post Management</p>

        {/* <NavLink to="/create/post/category" className="menu__item flex menu_link gap-4 items-center ">
                <span className="menu__icon"><AiOutlineDashboard/></span> 
                  Add Post Category
                </NavLink> */}

        <NavLink to="/post/category" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Post Category List
        </NavLink>
        <NavLink to="/create/post/category" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Add New Category
        </NavLink>

        <NavLink to="/posts" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Posts
        </NavLink>

        <NavLink to="/create/post" className="menu__item flex menu_link gap-4 items-center ">
                <span className="menu__icon"><AiOutlineDashboard/></span> 
                  Add New Post
        </NavLink>

      </div>

      <div className="border-b py-5">
        <p>Forecast Management</p>

        <NavLink to="/forecast" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Forecast List
        </NavLink>
        <NavLink to="/create/forecast" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Add New Forecast
        </NavLink>

      </div>

      <div className="border-b py-5">
        <p>Free Tools </p>
        <NavLink to="/free-tools/product-guide" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Product Guide
        </NavLink>

        <NavLink to="/free-tools/add/product-guide" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Add Product Guide
        </NavLink>

        <NavLink to="/consultations" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
          Consultation
        </NavLink>

        <NavLink to="/create/consultation" className="menu__item flex menu_link gap-4 items-center ">
          <span className="menu__icon"><AiOutlineDashboard /></span>
         Add Consultation
        </NavLink>
      </div>

      <div className="my-3 border-y py-5">
        <p>Setting Management</p>

          <NavLink to="/site/setting" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard/></span> 
              Site Setting
          </NavLink>

          <NavLink to="/newsletter" className="menu__item flex menu_link gap-4 items-center ">
              <span className="menu__icon"><AiOutlineDashboard/></span> 
              Newsletter
          </NavLink>
      </div>

    </div>
  );
};

export default Sidebar;