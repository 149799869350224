// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import baseApiUrl from '../../../utility/baseApiUrl';
import { Link, useParams, Navigate } from 'react-router-dom';
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import Layout from '../../../components/Layout/Layout';
import apiStoragePath from '../../../utility/apiStoragePath';
import useAuth from '../../../hooks/useAuth';


const axios = require("axios");
const EditImage = () => {

  const imagePath = apiStoragePath();
  const auth = useAuth();

  let { id } = useParams();

  const dispatch = useDispatch();

  const ref = useRef();
    const pdf_file_ref = useRef();


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const [formTitle] = useState('Edit Product Guide');

  const [image, setImage] = useState({}); // changeHere - stateName correction
  const [showImage, setShowImage] = useState({}); // changeHere - stateName correction
  const [pdfFile, setPDFFile] = useState({}); // changeHere - stateName correction


  const [form, setForm] = useState({
    image_title: '',
    pdf_file: '',
  });


  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })

    if (e.target.files[0]) {
      setImage(e.target.files[0])
    }
  }

  const uploadPDF = e =>{
    setPDFFile(e.target.files[0])
    // console.log('pdf_1o1', e.target.files)
 }

  // load category from server
  const loadImage = () => {
    axios
      .get(baseApiUrl() + "image/" + id)
      .then((res) => {
        setForm(res.data);
        setShowImage(res.data.image);
        dispatch(SET_PAGE_LOADER(false));

      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));
        console.log('server error', err);
        alert('Please reload again');
      })
  };


  useEffect(() => {
    dispatch(SET_PAGE_LOADER(true));
    loadImage();
  }, []); 


  //   update category
  const onSubmit = e => {

    e.preventDefault();

    dispatch(SET_PAGE_LOADER(true));

    let data = new FormData();
    data.append('image_title', form.image_title);

    if (image?.name) {
      data.append("image", image);
    }
    if (pdfFile?.name) {
      data.append("pdf_file", pdfFile);
    }

    const apiUrl = baseApiUrl();

    axios.post(`${apiUrl}image-update/${id}`, data).then(res => {

      console.log(res)

      Toast.fire({
        icon: 'success',
        title: res.data.msg
      });
      dispatch(SET_PAGE_LOADER(false));

      loadImage();

    }).catch((err) => {

      console.log(err);
      dispatch(SET_PAGE_LOADER(false));

      Toast.fire({
        icon: 'error',
        title: 'server error'
      });
    });

    console.log(data)
  }

  return (
    <Layout>
      <div>
        <div className='lg:px-24 gap-10'>
          <div className='w-full bg-white rounded-md border shadow' >
          <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                   <h3>{formTitle}</h3>
                <button className='button__bg px-6 py-2 rounded'><Link to={'/free-tools/product-guide'}>Product Guide Lists</Link>
              </button>
            </div>
               <form onSubmit={onSubmit} className='py-5 px-8'>
                   <div className=''>

                       <div className=''>
                           <div className="w-full mb-6">
                               <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Product Guide Title</label>
                                <input type="text" 
                                       name='image_title'
                                       value={form.image_title}
                                       onChange={handleChange} 
                                className="form-field" required />
                           </div>


                           <div className="w-full mb-6">
                               <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>
                                   <div>
                                       <div>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Product Guide Image</label>
                                            <input  type="file" ref={ref} className='border-2 rounded py-1 px-1 w-full'
                                                onChange={handleChange} 
                                                onClick={e => (e.target.value = null)}
                                            />
                                        </div>

                                       <div className='mt-6'>
                                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Product Guide PDF File</label>
                                            <input  type="file" ref={pdf_file_ref} className='border-2 rounded py-1 px-1 w-full'
                                                onChange={uploadPDF}
                                                onClick={e => (e.target.value = null)}
                                            />

                                            <div className='mt-4'>
                                                 {pdfFile.name ? 
                                                  <div> PDF File :  {pdfFile.name}
                                                 </div>
                                                  : <div> PDF File :  {form.pdf_file}
                                                 </div>
                                                 }
                                            </div>
                                        </div>
                                   </div>

                                   <div className='flex lg:justify-end md:justify-end '>

                                        {image.name ?
                                           <div className="mt-3">
                                               <img src={URL.createObjectURL(image)} alt="" className="w-full h-40 shadow-sm object-cover " />
                                            </div>

                                          : <div className="mt-3">
                                               <img src={imagePath + showImage} alt=""  className="w-full h-40 shadow-sm object-cover " />
                                            </div>
                                        }
                                   </div>
                               </div>
                               
                           </div>
                       </div>


                <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </Layout>
  );
};

export default EditImage;