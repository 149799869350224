// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../components/Layout/Layout';
import useAuth from '../../../hooks/useAuth';
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction";
import baseApiUrl from '../../../utility/baseApiUrl';

const axios = require("axios");

const EditUser = () => {

    const dispatch = useDispatch();
    let { id } = useParams();
    const auth = useAuth();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    const [formTitle] = useState('Edit User');

    const [form, setForm] = useState({
        name : '',
        email : '',
        role : '',
        first_name : '',
        last_name : '',
        display_name : '',
        password : '',
    });

    const resetForm = () =>{
        setForm({
            name : '',
            email : '',
            role : '',
            first_name : '',
            last_name : '',
            display_name : '',
            password : '',
        })

    }


    const handleChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value })
        
     }

     


     const onSubmit = e =>{

        dispatch(SET_PAGE_LOADER(true));

        e.preventDefault();

        // axios.post('http://2-pakbettv-serverside.test/api/user/update/'+id, form).then(res => {
        axios.post(baseApiUrl() + 'user/update/'+id, form).then(res => {
                       
            Toast.fire({
                icon: 'success',
                title: res.data.msg
              });

              dispatch(SET_PAGE_LOADER(false));


        }).catch((err) => {
            
           
            Toast.fire({
                icon: 'error',
                title: 'Server error'
            });
            
            dispatch(SET_PAGE_LOADER(false));

                      
        });

     }


     // load category from server
  const loadUser = () => {
    axios
      .get(baseApiUrl() + "user/edit/"+id)
      .then((res) => {
                
        setForm(res.data)


        dispatch(SET_PAGE_LOADER(false));


      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));
        console.log('error', err)
      })

  };

  useEffect(() => {

    dispatch(SET_PAGE_LOADER(true));
    loadUser();
  }, []); 

    return (
        <Layout>
        <div>
        <div className='lg:px-24 gap-10'>
            <div className='w-full bg-white rounded-md border shadow' >
            <div className='px-4 py-2 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                   <h3>{formTitle}</h3>

                   <Link to={'/users'}>
                     <button className='button__bg px-6 py-2 block rounded'>User Lists</button>
                   </Link>
            </div>
               <form onSubmit={onSubmit} className='py-5 px-8'>
                   <div className=''>

                       <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                            <div className="w-full mb-6">
                                <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">User Name</label>
                                <input type="text"
                                name='name'
                                value={form.name}
                                onChange={handleChange}
                                className="form-field" />
                                </div>

                            </div>

                            <div className="w-full mb-6">
                                <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
                                <input type="email"
                                name='email'
                                value={form.email}
                                onChange={handleChange}
                                className="form-field"  />
                                </div>
                            </div>
                        </div>

                    
                       <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                            <div className="w-full mb-6">
                                <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Password </label>
                                <input type="password"
                                name='password'
                                value={form.password}
                                onChange={handleChange}
                                className="form-field" />
                                </div>

                            </div>

                        </div>


                       <div className='flex flex-wrap gap-3 '>
                            <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                            <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p>
                       </div>
                   </div>
               </form>
            </div>

        </div>
      </div>
        </Layout>
    );
};

export default EditUser;