// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from 'react-quill'; // ES6
import Multiselect from 'multiselect-react-dropdown';

import 'react-quill/dist/quill.snow.css'; // ES6
import { useDispatch } from 'react-redux';
import { Link, Navigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from "../../../components/Layout/Layout";
import useAuth from "../../../hooks/useAuth";
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction";
import apiStoragePath from '../../../utility/apiStoragePath';
import baseApiUrl from '../../../utility/baseApiUrl';
import localBaseApiUrl from '../../../utility/localBaseApiUrl';
import formatCurrency from '../../../utility/formatCurrency';

import "./style.css";
const axios = require("axios");
const EditProduct = () => {

    const serverApi = baseApiUrl();

    const auth = useAuth();
   let { id } = useParams();
    const dispatch = useDispatch();
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    // const imagePath = "http://127.0.0.1:8000/storage/images/";
    const imagePath = apiStoragePath();

    const imageRef = useRef();
    const imgRef = useRef();

    const muiltiImageRef = useRef();

    // ReactQuill editor
    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "mention"
      ];

      const   modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"]
        ]
      };

    const [formTitle] = useState('Edit Product');

    const [image, setImage] = useState({}); // changeHere - stateName correction

    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);

    const [form, setForm] = useState({
        product_type: '',
        product_qty: '',
        product_sku: '',
        product_title: '',
        product_sale_price: '',
        product_regular_price: '',
        product_feature: '',
        product_badge_id: '',
        product_image: '',
        youtube_link: '',
        product_category_id: '',
    });


    const [productDescription, setProductDescription] = useState("");
    const [productShortDescription, setProductShortDescription] = useState("");

    const [product_category_id, setProductCategoryId] = useState("");
    const [product_sub_category_id, setProductSubCategoryId] = useState("");

    const [show_Best_Seller_Top_Tated, setShowBestSellerTopRated] = useState(false);
    const [best_seller, setBestSeller] = useState('');
    const [top_rated, setTopRated] = useState('');

    // load subcategory from server
    const loadSubCategory = id => {
        axios
            .get(baseApiUrl() + "backend-subcategory/"+id)
            .then((res) => {
              
                if(res.data.errorMsg){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.errorMsg
                    });
                }else{
                    setSubCategory(res.data);
                }
            })
            .catch((err) => console.error(err))
            .then(() => {
                console.log("final done");
        });
    };

    const handleChangeCategory = cat_id =>{
       
        setProductCategoryId(cat_id);
        loadSubCategory(cat_id);

        const result = category.find(item => item.id == cat_id);

        if(result?.category_name === 'Auspicious Figurines' || result?.category_name === 'Feng Shui Bracelets'){
            setShowBestSellerTopRated(true)
        }else{
            setShowBestSellerTopRated(false)
        }
    }


    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value })

        if (e.target.files[0]) {
            setImage(e.target.files[0])
        }
    };

    const set_Product_Des = value => {
        setProductDescription(value);
    };

    const set_Product_Short_Des = value => {
        setProductShortDescription(value);
    }



    // handleMultipleImage
    let productGalleyImages = [];

    const [galleryImages, setGalleryImages] = useState([]);
    const [existingGalleryImages, setExistingGalleryImages] = useState([]);

    const handleMultipleImage = e => {

        let droppedFiles = e.target.files;

        if (!droppedFiles) return;

        ([...droppedFiles]).forEach(f => {
            productGalleyImages.push(f);
        });

        setGalleryImages([...productGalleyImages,...galleryImages]);

        // console.log('multipleimg-101', productGalleyImages)
    }


    // remove Gallery Image function
    const removeGalleryImage = image =>{
        
        let index = galleryImages.indexOf(image);
                    galleryImages.splice(index, 1);

        let p_Galley_Im_i = productGalleyImages.indexOf(image);
                            productGalleyImages.splice(p_Galley_Im_i, 1);

        setGalleryImages([...productGalleyImages,...galleryImages]);
    };


    const resetForm = () => {
        // setForm({
        //     product_title: '',
        //     product_sale_price: '',
        //     product_regular_price: '',
        //     product_feature: '',
        //     product_badge_id: '',
        //     product_category_id: '',
        // });
    
        // setProductDescription("");
        // setProductShortDescription("");
        imageRef.current.value = "";
        muiltiImageRef.current.value = "";
        setGalleryImages([]);
        // setImage({});
    }



    // load category from server
    const loadCategory = () => {

        dispatch(SET_PAGE_LOADER(true));

        axios
            .get(baseApiUrl() + "backend-category-data/product")
            .then((res) => {
                setCategory(res.data);
                dispatch(SET_PAGE_LOADER(false));

            })
            .catch((err) => {
                dispatch(SET_PAGE_LOADER(false));
                console.log('server error', err);
                alert('Please reload again');
            })
        
    };


    // load Post from server
    const loadProduct = () => {
      console.log('test_606', id)
      axios
        // .get("http://2-pakbettv-serverside.test/api/products/"+id)
        .get(serverApi+"products/"+id)
        .then((response) => {
          
          dispatch(SET_PAGE_LOADER(false));
          
          if(response.status >= 200 && response.status < 300){
           
            setForm(response.data.data);
           
            const data = response.data.data;

            setProductCategoryId(data.product_category_id);
            setProductSubCategoryId(data.product_sub_category_id);

            handleChangeCategory(data.product_category_id);

            if(data.best_seller === '1' || data.top_rated === '1'){
                setShowBestSellerTopRated(true);
                setBestSeller(data.best_seller);
                setTopRated(data.top_rated);
            }


            setProductDescription(response.data.data.product_description);
            setProductShortDescription(response.data.data.product_short_description);            

            if(response.data?.gallery.length){
              setExistingGalleryImages(response.data.gallery)
            }else{
                setExistingGalleryImages([]);
            }

            if(response?.data?.product_variations){
                console.log('response_10data', response.data.product_variations[0]['attributes'])
                setAllOldVariations(response.data.product_variations);
            }

          }

        //    Toast.fire({
        //         icon: 'success',
        //         title: 'if data is missing. Reload again'
        //     });
          
        })
        .catch((err) => {
          dispatch(SET_PAGE_LOADER(false));
          console.log('post data server error', err);
            // Toast.fire({
            //     icon: 'error',
            //     title: 'if data is missing. Reload again'
            // });
        })
    };
  
    useEffect(() => {
      dispatch(SET_PAGE_LOADER(true));
      loadProduct();
    }, []);


    const onSubmit = e => {

        e.preventDefault();

        dispatch(SET_PAGE_LOADER(true));
         
        let data = new FormData();
        data.append('product_type', form.product_type);
        data.append('product_sku', form.product_sku);
        data.append('product_qty', form.product_qty);
        data.append('product_category_id', product_category_id);
        data.append('product_title', form.product_title);
        data.append('youtube_link', form.youtube_link);
         
        if (form.product_sale_price > 1) {
            data.append('product_sale_price', parseInt(form.product_sale_price));
        } else {
            data.append('product_sale_price', '');
        }
        if (form.product_regular_price > 1) {
            data.append('product_regular_price', parseInt(form.product_regular_price));
        } else {
            data.append('product_regular_price', '');
        }
       
        data.append('product_feature', form.product_feature);

        // data.append('product_badge_id', form.product_badge_id);
        data.append('product_description', productDescription);
        data.append('product_short_description', productShortDescription);


        if(show_Best_Seller_Top_Tated){
            data.append('best_seller', best_seller);
            data.append('top_rated', top_rated);
        }

        if (image?.name) {
            data.append("product_image", image);
        }

        if (galleryImages) {

            for(let i = 0; i < galleryImages.length; i++){
                 let file = galleryImages[i];
                 data.append('product_gallery['+ i + ']', file || '');
             }
        }
        data.append('allVariations', JSON.stringify(allVariations));
        if(allVariations.length){
            for (let i = 0; i < allVariations.length; i++) {
                let file = allVariations[i]['variationImage'];
                data.append('variationImages[' + i + ']', file || '');
            }
        }


        axios.post(`${serverApi}product/update/${id}`, data).then(res => {           
            setAllVariations([])
            resetForm();
            loadProduct();
            dispatch(SET_PAGE_LOADER(false));

            // if(existingGalleryImages.length === 0){
            //   window.location.reload()
            // }

            Toast.fire({
                icon: 'success',
                title: res.data.msg
            });


        }).catch((err) => {
            
            console.log(err);
            dispatch(SET_PAGE_LOADER(false));

            Toast.fire({
                icon: 'error',
                title: 'Try Again'
            });
        });

    }


    const single_galleryImageDelete = img_id =>{
     
      dispatch(SET_PAGE_LOADER(true));

      const apiUrl = baseApiUrl();

      axios.post(`${apiUrl}product/gallery-image-delete/`+img_id).then(res => {

          loadProduct();
          
          dispatch(SET_PAGE_LOADER(false));

            Toast.fire({
                icon: 'success',
                title: res.data.msg
            });


        }).catch((err) => {
            
            dispatch(SET_PAGE_LOADER(false));

            Toast.fire({
                icon: 'error',
                title: 'server error'
            });
        });
    }


    useEffect(() => {
        loadCategory();
    }, []);  



     // attributes data selection function etc

     const [attributes, setAttribute] = useState([]);

     // load attribute from server
     const loadAttribute = () => {
 
         dispatch(SET_PAGE_LOADER(true));
         // baseApiUrl()
         axios
             .get(baseApiUrl()+"product-create-attributes")
             .then((response) => {
                 dispatch(SET_PAGE_LOADER(false));
                 setAttribute(response.data);
             })
             .catch((err) => {
                 dispatch(SET_PAGE_LOADER(false));
                 console.log('product data server error', err);
                 alert('Please reload again');
             })
     };
 
 
 
 
     // End attributes data selection function etc
 
     useEffect(() => {
         loadCategory();
         loadAttribute();
     }, []);
 
 
 
     // start product variation function
 
     const [optionList, setOptionList] = useState([]);
 
     const optionListFunc = () => {
 
         console.log('test_101', attributes)
         let tempOptionList = [];
 
         attributes.forEach(element => {
             // console.log('test_102')
             // console.log('test_103', element.name)
             const attributeValueList =  element.attribute_items;
             // console.log('test_104', attributeValueList)
 
             let tempAttributeValueList = [];
             attributeValueList.forEach(attrElement => {
                 console.log('test_105', attrElement.attribute_item)
                 const tempAttr =  {
                     name: element.name,
                     attribute_item: attrElement.attribute_item,
                     attribute_value: attrElement.attribute_value,
                     attribute_id: element.id,
                     attribute_item_id: attrElement.id,
                 };
                 tempAttributeValueList.push(tempAttr);
             })
             // console.log('test_106', tempAttributeValueList)
             // tempOptionList.push(tempAttributeValueList);
             tempOptionList =[...tempOptionList,...tempAttributeValueList]
             
          });
         //  console.log('test_107', tempOptionList)
 
 
         setOptionList(tempOptionList);
 
     }
 
 
 
 
     const [selectedValue, setSelectedValue] = useState([])
 
     const handleOnSelectOnMS  =( item )=>{
         setSelectedValue(item);
     }
 
     const [filterSelectedValue, setFilterSelectedValue] = useState([])
 
     // filter all attributes and make new attributes objects of arry
     const updateAttribute = ()=>{
 
         let tempOptionList = [];
         let uniqueObjArray = [ ...new Map (selectedValue.map((item) => [item["name"], item])).values(), ];
           if(uniqueObjArray){
                 selectedValue.forEach(item =>{
                     uniqueObjArray.forEach(element => {
                           if(item.attribute_id === element.attribute_id){
                             
                             let found = tempOptionList.find(attElement => attElement.attribute_id === item.attribute_id);
                            
                             if(found){
                                 found.attributes.push(item);
                                 tempOptionList.push(found);
                                 tempOptionList = [ ...new Map (tempOptionList.map((item) => [item["name"], item])).values(), ];
                                 setFilterSelectedValue([...tempOptionList]);
                             }else{
                                   let tempAttr =  {
                                     name: item.name,
                                     attribute_id: element.attribute_id,
                                     attributes: [item],
                                   };
                                   tempOptionList.push(tempAttr);
                                   tempOptionList = [ ...new Map (tempOptionList.map((item) => [item["name"], item])).values(), ];
                                   setFilterSelectedValue([...tempOptionList]);
                             }
                             }
                     });
             
                 })
           }
 
 
     }
 
 
 
     const [variationSKU, setVariationSKU] = useState('')
     const [variationQTY, setVariationQTY] = useState('')
     const [variationImage, setVariationImage] = useState({})
     const [variationPrice, setVariationPrice] = useState('');
     
     
     const [temDynamicData, setDynamicAttribute] = useState([]);
 
     const getLoopAttributeVal = e =>{
        let tem_data = temDynamicData

         let attributeE = e[0];
       
         if(attributeE){
            let found = tem_data.find(attElement => attElement.attribute_id === attributeE.attribute_id);
             if(found){
                 
                let index = tem_data.indexOf(found);
                    tem_data.splice(index,1)

                    let test = tem_data;
                    test.push(attributeE);
                    setDynamicAttribute(test);
             }else{

                 let test = temDynamicData;
                 test.push(attributeE);
                 setDynamicAttribute(test);
             }
        }
     }
 
     
     const [allVariations, setAllVariations] = useState([]);
     const [allOldVariations, setAllOldVariations] = useState([]);
 
     const addVariation = e =>{
 
         e.preventDefault();
 
         let tem_data = {
             variationSKU   : variationSKU,
             variationQTY   : variationQTY,
             variationImage : variationImage,
             variationPrice : variationPrice,
             attributes     : temDynamicData,
         }
 
         setAllVariations([...allVariations,tem_data]);
         setDynamicAttribute([]);
         setVariationQTY('');
         setVariationSKU('');
         setVariationPrice('');
 
         if(variationImage?.name){
             setVariationImage({});
             imgRef.current.value = "";
         }
     }
 
     const removeVariation = (itemIndex) =>{
         let tem_data = [...allVariations];
         tem_data.splice(itemIndex, 1);
         setAllVariations(tem_data);
     }
      


     const removeOldVariation = itemID => {
 
        dispatch(SET_PAGE_LOADER(true));
        // baseApiUrl()
        axios
            .delete(baseApiUrl()+"delete-product-variation/"+itemID)
            .then((response) => {
                // dispatch(SET_PAGE_LOADER(false));
                loadProduct();
            })
            .catch((err) => {
                dispatch(SET_PAGE_LOADER(false));
                console.log('product data server error', err);
                alert('Please reload again');
            })
    };

 
     useEffect(() => {
 
         if (attributes) {
             optionListFunc();
         }
     }, [attributes]);

    return (
        auth ? <>
            <Layout>
                <form onSubmit={onSubmit}>
                    <div className='lg:flex gap-5'>

                        {/* left component */}
                        <div className='lg:w-8/12 bg-white rounded-md border shadow' >

                            <div className='px-4 py-4 mb-5 card__header border-2 text-md flex items-center justify-between '>
                                <h3>{formTitle}</h3>
                                <Link to={'/products'}>
                                <span className='button__bg px-6 py-3 rounded'>Product Lists</span>
                                </Link>
                            </div>

                            <div className='py-5 px-8'>

                                <div className="w-full mb-6">
                                    <label className="form-label">Product title</label>
                                    <input type="text"
                                        name='product_title'
                                        value={form.product_title}
                                        onChange={handleChange}
                                        className="form-field"  />
                                </div>

                                {form.product_type == "1" &&  <>
                                <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                                    <div className="w-full mb-6">
                                        <label htmlFor="email" className="form-label">Regular Price</label>
                                        <input type="number"
                                            name='product_regular_price'
                                            value={form.product_regular_price}
                                            onChange={handleChange}
                                            className="form-field"  />
                                    </div>

                                    <div className="w-full mb-6">
                                        <label htmlFor="email" className="form-label">Sale Price</label>
                                        <input type="number"
                                            name='product_sale_price'
                                            value={form.product_sale_price}
                                            onChange={handleChange}
                                            className="form-field" />
                                    </div>
                                </div>

                                <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                                    <div className="w-full mb-6">
                                        <label className="form-label">Product QTY</label>
                                        <input type="number"
                                            name='product_qty'
                                            value={form.product_qty}
                                            onChange={handleChange}
                                            className="form-field" />
                                    </div>

                                    <div className="w-full mb-6">
                                        <label  className="form-label">Product SQU</label>
                                        <input type="text"
                                            name='product_sku'
                                            value={form.product_sku}
                                            onChange={handleChange}
                                            className="form-field" />
                                    </div>
                                    </div>
                                    </> }


                                <div className="mb-6 short-description-editor">
                                    <label className="form-label">Short Description</label>
                                    <ReactQuill value={productShortDescription}
                                    onChange={set_Product_Short_Des} 
                                    modules={modules}
                                    formats={formats}
                                    />

                                </div>

                                <div className="mb-6">
                                    <label className="form-label">Long Description</label>

                                    <ReactQuill value={productDescription}
                                    onChange={set_Product_Des} 
                                    modules={modules}
                                    formats={formats}
                                    />
                                </div>

                            </div>
                        </div>

                        {/* right component    */}
                        <div className='phone-mt lg:w-4/12'>
                            <div className='w-full bg-white rounded-md border shadow' >
                                <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
                                    <h3 className="py-2">{formTitle}</h3>
                                </div>

                                
                                <div className='py-5 px-8'>

                                <div className="mb-6">
                                        <label className="form-label">Product Type</label>
                                        <select
                                            required
                                            name='product_type'
                                            value={form.product_type}
                                            onChange={handleChange}
                                            className="select-field"
                                        >
                                            <option value="1">Simple Product</option>
                                            <option value="2">Variation Product</option>
                
                                        </select>
                                    </div>

                                    <div className="mb-6">
                                        <label htmlFor="email" className="form-label">Select Category</label>
                                    
                                        <select 
                                            value={product_category_id}
                                            onChange={e => handleChangeCategory(e.target.value)}
                                            className="select-field"
                                        >
                                            <option value="" selected>Select Category</option>
                                            {category.map(item => {
                                                return (
                                                    <option key={item} value={item.id}>{item.category_name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>

                                    <div className="mb-6">
                                        <label  className="form-label">Sub-Category - ({subCategory.length ? '-' : 'No Sub-category'}) </label>
                                        <select 
                                            value={product_sub_category_id}
                                            onChange={e => setProductSubCategoryId(e.target.value)}
                                            className="select-field"
                                        >
                                            <option value="" selected>Select Category</option>
                                            {subCategory.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.id}>{item.category_name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>


                                    {show_Best_Seller_Top_Tated && <>
                                    
                                    <div className="mb-6">
                                        <label  className="form-label">Best Seller</label>

                                        <select 
                                            value={best_seller}
                                            onChange={e => setBestSeller(e.target.value)}
                                            className="select-field"
                                        >
                                            <option value="" selected>Select</option>
                                            <option value="2" selected>Hide</option>
                                            <option value="1" selected>Show</option>
                                        </select>

                                    </div>

                                    <div className="mb-6">
                                        <label  className="form-label">Top Rated</label>
                                        <select 
                                            value={top_rated}
                                            onChange={e => setTopRated(e.target.value)}
                                            className="select-field"
                                        >
                                            <option value="" selected>Select</option>
                                            <option value="2" selected>Hide</option>
                                            <option value="1" selected>Show</option>
                                        </select>
                                    </div>
                                </>}

                                <div className="w-full mb-6">
                                        <label className="form-label">Youtube Video ID</label>
                                        <input  type="text" className='border-2 rounded py-1 px-1 w-full'
                                            onChange={handleChange}
                                            value={form.youtube_link}
                                            name="youtube_link"
                                        />
                                    </div>

                                    <div className="w-full mb-6">
                                <label className="form-label">Featured  Image</label>
                                <input   type="file"  ref={imageRef}  className='mb-3 border-2 rounded py-1 px-1 w-full'
                                    onChange={handleChange} 
                                    onClick={e => (e.target.value = null)}
                                    />

                                    {image.name ? 

                                    <img src={URL.createObjectURL(image)} alt="img" className="w-full h-32 shadow-sm object-cover"/>

                                    : <img src={imagePath + form.product_image} alt="img" className="w-full h-32 shadow-sm object-cover"/>
                                    }

                                    <div>
                                    </div>
                            </div>


                                    <div className="w-full mb-6">
                                        <label className="form-label">Product Gallery Image</label>
                                        <input multiple type="file" ref={muiltiImageRef}className='border-2 rounded py-1 px-1 w-full'
                                            onChange={handleMultipleImage}
                                            onClick={e => (e.target.value = null)}
                                        />

                                        {/* show gallery images */}
                                        <div className="mt-5 flex flex-wrap gap-3">
                                            {galleryImages?.map((item, index) => {
                                                return (
                                                    <div key={index} className="shadow-md ">
                                                        <div className="relative ">
                                                        <img src={URL.createObjectURL(item)} alt="" srcset="img" className="gallery__image" />
                                                        <span onClick={() => removeGalleryImage(item)} className=" text-center cursor-pointer absolute left-0 top-0 bg-red-500 text-sm text-white rounded-full pt-0.5 h-6 w-6 ">X</span>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>


                                        {/* show gallery images */}
                                        <div className="mt-5">
                                            {existingGalleryImages.length ? 
                                            <>
                                                <h3 className="mb-3">Existing Images</h3>
                                                    <div className="flex flex-wrap gap-3">
                                                        {existingGalleryImages?.map((item, index) => {
                                                            return (
                                                                <div key={index} className="shadow-md ">
                                                                    <div className="relative ">
                                                                        <img src={imagePath+item.image} alt="" className="gallery__image" />
                                                                        <span onClick={() => single_galleryImageDelete(item.id)} className=" text-center cursor-pointer absolute left-0 top-0 bg-red-500 text-sm text-white rounded-full pt-0.5 h-6 w-6 ">X</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </>
                                            : ''}
                                        </div>

                                    </div>

                                    <div className='flex flex-wrap gap-3 '>
                                        <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                                        {/* <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>


               {form.product_type == '2' &&
                    <div className='mt-5 mb-10 bg-white rounded-md border shadow' >
                        <div className='text-lg px-4 py-4 mb-5 card__header border-2 flex items-center justify-between '>
                            <h3>Product Variation</h3>
                        </div>
                            <div className=' white-space mb-20 py-5 px-8'>
                                <div className='flex items-center gap-5'>
                                    <div className='w-full'>
                                        {/* Multiselect  */}
                                        <label className="form-label text-lg">Select Attributes</label>
                                        <section>
                                            <Multiselect
                                                displayValue="attribute_item"
                                                groupBy="name"
                                                // onKeyPressFn={function noRefCheck(){}}
                                                onRemove={handleOnSelectOnMS}
                                                // onSearch={function noRefCheck(){}}
                                                onSelect={handleOnSelectOnMS}
                                                options={optionList}
                                                showCheckbox
                                            />
                                        </section>
                                        {/* Multiselect  */}
                                    </div>
                                    <div>
                                    <p onClick={updateAttribute} className="mt-8 cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Update</p>
                                    </div>
                                </div>
                            
                            <div className='mb-8 mt-8 border-t-2 pt-3 pb-8'>
                                <form onSubmit={addVariation} >
                                    {filterSelectedValue.length  ? 
                                        <div className='flex gap-2'>
                                            {/* loop attributes fiels */}
                                            <div className='flex w-full gap-4'>
                                                    {filterSelectedValue?.map(item =>{
                                                        return(
                                                            <div key={item}>
                                                                <label className="form-label">{`Select ${item.name}`}</label>

                                                                <Multiselect
                                                                options={item.attributes} // Options to display in the dropdown
                                                                //    selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                                onSelect={getLoopAttributeVal} // Function will trigger on select event
                                                                onRemove={getLoopAttributeVal} // Function will trigger on remove event
                                                                displayValue="attribute_item" // Property name to display in the dropdown options
                                                                singleSelect
                                                                />
                                                                
                                                            </div>
                                                        )
                                                    })}
                                            </div>

                                            {/* fixed attributes fiels */}
                                            <div className="w-full mb-6">
                                                <label className="form-label">SKU</label>
                                                <input type="text"
                                                    value={variationSKU}
                                                    onChange={e =>setVariationSKU(e.target.value)}
                                                    className="form-field" />
                                            </div>
                                            <div className="w-full mb-6">
                                                <label className="form-label">QTY</label>
                                                <input type="number"
                                                    value={variationQTY}
                                                    onChange={e =>setVariationQTY(e.target.value)}
                                                    className="form-field" />
                                            </div>
                                            <div className="w-full mb-6">
                                            <label className="form-label">Image</label>
                                                <input  type="file" ref={imgRef} className='border-2 rounded py-1 px-1 w-full'
                                                    onChange={e => setVariationImage(e.target.files[0])}
                                                    onClick={e => (e.target.value = null)}
                                                />
                                            </div>
                                            <div className="w-full mb-6">
                                                <label className="form-label">Price</label>
                                                <input type="number"
                                                        required
                                                    value={variationPrice}
                                                    onChange={e =>setVariationPrice(e.target.value)}
                                                    className="form-field" />
                                            </div>
                                            <button type='submit' className="mt-7 cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-md px-5 h-10 pt-1  text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Add</button>
                                        </div>
                                    : ''}
                                </form>
                            </div>

                                <div className='-mt-4 w-full'>
                                {allVariations.length ? <>
                                    <h1 className='text-lg border-y-2 py-2'>Product Variations</h1>
                                    <div className='w-full border-b border-gray-200 shadow mt-5'>
                                        <table className="'w-full divide-y divide-gray-300">
                                            <thead className='bg-gray-50'>
                                                <tr>
                                                    <th className='px-6 text-left py-2 text-md text-gray-700'>Image</th>
                                                    <th className='px-6 text-left py-2 text-md text-gray-700'> Attributes</th>
                                                    <th className='px-6 py-2 text-md text-gray-700'>SKU</th>
                                                    <th className='px-6 py-2 text-md text-gray-700'>QTY</th>
                                                    <th className='px-6 py-2 text-md text-gray-700'>Price</th>
                                                    <th className='px-6 py-2 text-md text-gray-700'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className='bg-white divide-y divide-gray-300'>
                                                {allVariations.map((item,index) =>{
                                                        return(
                                                            <tr className='whitespace-nowrap' key={(item)}>
                                                                
                                                                <td className='px-6 py-4 text-sm text-gray-800'>
                                                                    {item.variationImage?.name ? 
                                                                    <img src={URL.createObjectURL(item.variationImage)} alt="img" className="w-20 h-12 object-cover  border-2 post__image "/>
                                                                    : 'No Image'}
                                                                </td>

                                                                <td className='px-6 py-4 text-sm items-center text-gray-800 flex gap-3 flex-wrap '>
                                                                    {item?.attributes.map(attributeData =>{
                                                                        return(
                                                                        <div key={attributeData} className='flex gap-4 flex-wrap '>
                                                                            <div className='flex gap-2'>
                                                                                <strong>{attributeData.name}</strong> : {attributeData.attribute_item}
                                                                            </div>
                                                                        </div>
                                                                        )
                                                                    })}
                                                                </td>
                                                                <td className='px-6  text-sm items-center text-gray-800'>{item.variationSKU ? item.variationSKU : 'No SKU'}</td>
                                                                <td className='px-6  text-sm items-center text-gray-800'>{item.variationQTY ? item.variationQTY : 'No QTY'}</td>
                                                                <td className='px-6  text-sm items-center text-gray-800'>{formatCurrency(item.variationPrice)}</td>
                                                                <td className='px-6  text-sm text-gray-800'>
                                                                    <button onClick={()=>removeVariation(index)} className="text-red-500" >Remove</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                                </> : ''}

                                {allOldVariations.length ? <>
                                    <h1 className='text-lg border-y-2 py-2'>Existing Product Variations</h1>
                                    <div className='w-full border-b border-gray-200 shadow mt-5'>
                                        <table className="'w-full divide-y divide-gray-300">
                                            <thead className='bg-gray-50'>
                                                <tr>
                                                    <th className='px-6 text-left py-2 text-md text-gray-700'>Image</th>
                                                    <th className='px-6 text-left py-2 text-md text-gray-700'> Attributes</th>
                                                    <th className='px-6 py-2 text-md text-gray-700'>SKU</th>
                                                    <th className='px-6 py-2 text-md text-gray-700'>QTY</th>
                                                    <th className='px-6 py-2 text-md text-gray-700'>Price</th>
                                                    <th className='px-6 py-2 text-md text-gray-700'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className='bg-white divide-y divide-gray-300'>
                                                {allOldVariations.map((item,index) =>{
                                                        return(
                                                            <tr className='whitespace-nowrap' key={(item)}>
                                                                
                                                                <td className='px-6 py-4 text-sm text-gray-800'>
                                                                    {item.image ? 
                                                                    <img src={imagePath+item.image} alt="img" className="w-20 h-12 object-cover  border-2 post__image "/>
                                                                    : 'No Image'}
                                                                </td>

                                                                <td className='px-6 py-4 text-sm items-center text-gray-800 flex gap-3 flex-wrap '>
                                                                    {console.log('test_707', (JSON.parse(item?.attributes)))}
                                                                   
                                                                    {(JSON.parse(item?.attributes)).map(attributeData =>{
                                                                        console.log('attributeData', attributeData)          
                                                                        return(
                                                                        <div key={attributeData} className='flex gap-4 flex-wrap '>
                                                                            <div className='flex gap-2'>
                                                                                <strong>{attributeData.name}</strong> : {attributeData.attribute_item}
                                                                            </div>
                                                                        </div>
                                                                        )

                                                                        // return console.log('attribute55Data', attributeData)
                                                                    })}
                                                                </td>
                                                                <td className='px-6  text-sm items-center text-gray-800'>{item.sku ? item.sku : 'No SKU'}</td>
                                                                <td className='px-6  text-sm items-center text-gray-800'>{item.qty ? item.qty : 'No QTY'}</td>
                                                                <td className='px-6  text-sm items-center text-gray-800'>{formatCurrency(item.price)}</td>
                                                                <td className='px-6  text-sm text-gray-800'>
                                                                    <button onClick={()=>removeOldVariation(item.id)} className="text-red-500" >Remove</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                                </> : ''}
                                </div>
                            </div>
                        
                    </div>
                }
            </Layout>
        </>:  <Navigate to="/login" />
    );
};

export default EditProduct;