// const initialState = false;
export const userLoginStatus = (state=null, action) =>{
    switch (action.type){
        case "login_status_update" : return action.payload;
        default : return state;
    }
}
export const loggedinUserInfo = (state={}, action) =>{
    switch (action.type){
        case "loggedinUserInfo_update" : return action.payload;
        default : return state;
    }
}
export const authUserPermission = (state="", action) =>{
    switch (action.type){
        case "authUserPermission_update" : return action.payload;
        default : return state;
    }
}

export const tokenValue = (state="", action) =>{
    switch (action.type){
        case "tokenValue_update" : return action.payload;
        default : return state;
    }
}


