// import baseApiUrl from '../../../../utility/baseApiUrl';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import baseApiUrl from '../../../utility/baseApiUrl';
import {SET_PAGE_LOADER} from "../../../redux/actions/loaderAction"
import { useDispatch } from 'react-redux';
import  Layout from '../../../components/Layout/Layout'
import useAuth from '../../../hooks/useAuth';
const axios = require("axios");

const CreateUser = () => {

    const dispatch = useDispatch();
    const auth = useAuth();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      });

    const [formTitle] = useState('Create User');


    const [form, setForm] = useState({
        name : '',
        email : '',
        role : '1',
        first_name : '',
        last_name : '',
        display_name : '',
        password : '',
        cin_id : '3001',
        password_confirmation : '',
    });

    const resetForm = () =>{
        setForm({
            name : '',
            email : '',
            role : '',
            first_name : '',
            last_name : '',
            display_name : '',
            cin_id : '3001',
            password : '',
            password_confirmation : '',
        })

    }


    const handleChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value })
        
     }

     


     const onSubmit = e =>{

        dispatch(SET_PAGE_LOADER(true));

        e.preventDefault();

        // let data = new FormData();
        // data.append('name', form.name);
        // data.append('name', form.name);

        axios.post(baseApiUrl() + 'users', form).then(res => {
                       
            Toast.fire({
                icon: 'success',
                title: res.data.msg
              });

              dispatch(SET_PAGE_LOADER(false));

              resetForm();

        }).catch((err) => {
            
            console.log('catch_error_11', err.response.data.errorMsg.password);
           
            if(err.response.data.errorMsg.email){
               
                Toast.fire({
                    icon: 'error',
                    title: 'The email has already been taken'
                });
                
            }
            else if (err.response.data.errorMsg){
                Toast.fire({
                    icon: 'error',
                    title: 'The password must be at least 6 characters'
                });
            }

            dispatch(SET_PAGE_LOADER(false));
                      
        });

     }

    return (
        <Layout>
        <div>
        <div className='lg:px-24 gap-10'>
            <div className='w-full bg-white rounded-md border shadow' >
            <div className='px-4 py-2 mb-5 card__header border-2 text-md flex flex-wrap gap-3 items-center justify-between '>
                   <h3>{formTitle}</h3>

                   <Link to={'/users'}>
                     <button className='button__bg px-6 py-2 block rounded'>User Lists</button>
                   </Link>
            </div>
               <form onSubmit={onSubmit} className='py-5 px-8'>
                   <div className=''>

                       <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                            <div className="w-full mb-6">
                                <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">User Name</label>
                                <input type="text"
                                name='name'
                                value={form.name}
                                onChange={handleChange}
                                className="form-field" required />
                                </div>

                            </div>

                            <div className="w-full mb-6">
                                <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
                                <input type="email"
                                name='email'
                                value={form.email}
                                onChange={handleChange}
                                className="form-field" 
                                required
                                 />
                                </div>
                            </div>
                        </div>

                       {/* <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                            <div className="w-full mb-6">
                                <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">First Name</label>
                                <input type="text"
                                name='first_name'
                                value={form.first_name}
                                onChange={handleChange}
                                className="form-field"
                                 />
                                </div>

                            </div>

                            <div className="w-full mb-6">
                                <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Last Name</label>
                                <input type="text"
                                name='last_name'
                                value={form.last_name}
                                onChange={handleChange}
                                className="form-field"  />
                                </div>
                            </div>
                        </div> */}

                       <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-4 md:gap-4 gap-1'>

                            <div className="w-full mb-6">
                                <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Password </label>
                                <input type="password"
                                name='password'
                                value={form.password}
                                onChange={handleChange}
                                className="form-field" required />
                                </div>

                            </div>

                            {/* <div className="w-full mb-6">
                                <div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Password Confirmation</label>
                                <input type="password"
                                name='password_confirmation'
                                value={form.password_confirmation}
                                onChange={handleChange}
                                className="form-field" required />
                                </div>
                            </div> */}
                        </div>


                       <div className='flex flex-wrap gap-3 '>
                            <button type="submit" className="text-white form__btn__bg  bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-8 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save</button>

                            <p onClick={resetForm} className=" cursor-pointer text-white form__btn__bg bg-purple-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-12 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Clear</p>
                       </div>
                   </div>
               </form>
            </div>

        </div>
      </div>
        </Layout>
    );
};

export default CreateUser;