
const pageLoaderReducer = (state = false, action) => {
   switch(action.type){
      case "PAGE_LOADER" : return action.payload
      // case "PAGE_LOADER" : return state =  !state;
      default : return state;
   }
};

export default pageLoaderReducer;
