import { React, useEffect, useState } from 'react';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Pagination from "react-js-pagination";
import { useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Layout from '../../../components/Layout/Layout';
import useAuth from "../../../hooks/useAuth";
import { SET_PAGE_LOADER } from "../../../redux/actions/loaderAction";
import apiStoragePath from '../../../utility/apiStoragePath';
import baseApiUrl from '../../../utility/baseApiUrl';
import formatCurrency from '../../../utility/formatCurrency';
import './style.css';
const axios = require("axios");

const Products = () => {
  const auth = useAuth();
  const [product, setproduct] = useState([]);
  const dispatch = useDispatch();
  const imagePath = apiStoragePath();

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
  });

  // load product from server
  const loadProduct = (pageNumber = 1) => {
    dispatch(SET_PAGE_LOADER(true));

    axios
      // .get("http://2-pakbettv-serverside.test/api/products")
      .get(baseApiUrl() + "products?page="+pageNumber)
      .then((response) => {
        
        dispatch(SET_PAGE_LOADER(false));
        
        if(response.status >= 200 && response.status < 300){
          setproduct(response.data);
          console.log('test_8080')
        }
        
      })
      .catch((err) => {
        dispatch(SET_PAGE_LOADER(false));
        console.log('product data server error', err);
          Toast.fire({
            icon: 'error',
            title: 'if data is missing. Reload again'
        });
      })
  };

  useEffect(() => {
    loadProduct();
  }, []);  



  // delete product
  const deleteproduct = id => {
    // console.log(form)

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        dispatch(SET_PAGE_LOADER(true));

        // axios.delete('http://2-pakbettv-serverside.test/api/products/' + id).then(res => {
        axios.delete(baseApiUrl() + 'products/' + id).then(res => {

          if (res.data.msg) {
            
            Swal.fire(
              'Deleted!',
              'Delete Success.',
              'success',
            )

            loadProduct();
            dispatch(SET_PAGE_LOADER(false));

          }

          else {
            dispatch(SET_PAGE_LOADER(false));

            Swal.fire(
              'Error!',
              'Server error try again.',
              'error',
            )
          }

        })
      }
    })
  }

  return (
    auth ? <>
      <Layout>
        <div className='bg-white rounded-lg'>

          <div className='px-4 py-2 mb-5 card__header border-2 text-md flex items-center justify-between '>
            <h3>Product List</h3>

              <Link to={'/create/product'}>
                      <button className='button__bg px-6 py-2 block rounded'>Add Product</button>
              </Link>

          </div>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-md text-gray-700 py-6  uppercase border-b-2 pb-3 border-gray-200  dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Product Image
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Product Title
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Product Price
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Product QTY
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className='mt-5'>
                {product?.data?.map(item => {
                  return (
                    <tr key={item} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        <img src={imagePath + item.product_image} className="w-16 border-2 " alt={item.product_title} />
                      </th>
                      <th className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {item.product_title}
                      </th>
                      <td className="px-6 py-4 text-black text-md font-bold ">
                   
                           {item.product_type == '1' ?  <>
                              {item.product_sale_price > 1 ? 
                              
                                  <p className='font-semibold '>
                                  <del className='text-red-600 '>{formatCurrency(item.product_regular_price)}</del>  
                                  <span className='ml-2 '>{formatCurrency(item.product_sale_price)}</span> </p>
                              : 
                                <p className=''><span> {formatCurrency (item.product_regular_price)}</span></p>
                              }
                           </>
                           
                          : <>
                            <span>Variable Product</span>
                          </>}

                      </td>
                      <td className="px-6 py-4 text-black text-md font-bold ">
                   
                           {item.product_type == '1' ?  <>
                              {item.product_qty}
                           </>
                          : <>
                            <span>Check Variable QTY</span>
                          </>}

                      </td>
                      <td className="px-6 py-4 mt-4 flex gap-5 text-2xl">
                        <span onClick={() => deleteproduct(item.id)} className=' cursor-pointer'> <AiOutlineDelete /> </span>
                        <Link to={`/edit/product/${item.id}`}> <AiOutlineEdit /></Link>
                      </td>
                    </tr>
                  )
                })}

              </tbody>
            </table>

            {product?.total > 5 && 
              <div className='pl-3 py-4'>
                  <Pagination
                      activePage={product?.current_page ? product?.current_page : 0}
                      itemsCountPerPage={product?.per_page ? product?.per_page : 0 }
                      totalItemsCount={product?.total ? product?.total : 0}
                      onChange={(pageNumber) => {
                        loadProduct(pageNumber)
                      }}
                      pageRangeDisplayed={8}
                      itemClass="page-item"
                      linkClass="page-link"
                      itemClassFirst="p-first-page-link"
                      itemClassLast="p-last-page-link"
                      activeClass="p-active"
                      activeLinkClass="p-active-link"
                      firstPageText="First Page"
                      lastPageText="Last Lage"
                  />
              </div>
             }
          </div>
        </div>
      </Layout>
    </>:  <Navigate to="/login" />
  );
};

export default Products;